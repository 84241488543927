<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Pedidos</span>
      </p>
      <sub-order-list-downloads
        :maker="maker"
        class="ml-auto"
      />
    </div>
    <div class="flex flex-col py-3 px-2 w-full bg-white rounded-md shadow-sm md:py-5 md:px-10">
      <p class="mb-1 text-sm text-gray-500 md:mb-0">
        Estados:
      </p>
      <template v-if="!(subOrderStatusIsLoading || subOrderIncidenceStatusIsLoading)">
        <lokal-single-selector
          v-if="isMobile || isMdScreen"
          v-model="selectedStatusRecord"
          key-option="id"
          class="mb-3 ml-auto w-full"
          :options="statusOptionRecords"
          option-label="name"
          options-label="Selecciona una dirección"
        />
        <div
          v-else
          class="flex items-center self-start mb-5 w-full text-sm lg:text-base"
        >
          <template
            v-for="value, key in filterStatusHash"
            :key="key"
          >
            <button
              class="flex shrink-0 items-center pt-1 pb-3 space-x-1 border-b-2"
              :class="{ 'border-gray-300' : selectedStatus !== key, 'border-lk-green' : selectedStatus === key }"
              @click="updateSelectedStatus(key)"
            >
              <span>{{ value }}</span>
              <span v-if="key === 'all'">({{ totalSubOrdersCount || 0 }})</span>
              <span v-else-if="key === 'incidence'">({{ subOrderIncidenceStatuses['incidenceInProgress'] || 0 }})</span>
              <span v-else>({{ subOrderStatuses[key] || 0 }})</span>
            </button>
            <div class="self-end last:w-full border-b-2 border-gray-300 lg:w-5 xl:w-10" />
          </template>
        </div>
        <div class="flex items-center mb-3 w-full">
          <form-input
            v-model="searchText"
            name="searchText"
            class="mr-2 w-full"
            label-name="Texto de búsqueda"
            placeholder="Busca por el ID de un pedido o el nombre de una tienda"
            :rules="{ required: false }"
            :disabled="isLoading"
          />
          <button class="p-2 text-xs hover:text-white focus:text-white hover:bg-lk-green focus:bg-lk-green rounded-md border border-lk-light-gray hover:border-lk-green focus:border-lk-green focus:outline-none md:text-sm">
            Buscar
          </button>
        </div>
      </template>
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <p
          class="flex pb-2 w-full bg-white"
        >
          <span class="hidden text-xl font-semibold text-gray-700 md:text-2xl">Pedidos</span>
          <span class="self-end ml-auto text-sm text-gray-700">
            {{ totalResources }} pedidos
          </span>
        </p>
        <sub-order-list-card
          v-for="subOrder in subOrders"
          :key="subOrder.id"
          :sub-order="subOrder"
          :first-sub-order="subOrders && subOrder.id === subOrders[0].id"
          :last-sub-order="subOrders && subOrder.id === subOrders[subOrders.length - 1].id"
          :show-details-link="true"
          :maker="maker"
          class="mb-2"
          @update:sub-order="reloadSubOrders"
        />
        <p
          v-if="totalResources === 0"
          class="text-sm md:text-base"
        >
          <span v-if="selectedStatus === 'all'">
            No tienes ningún pedido aún. No te preocupes, todo lo bueno toma su tiempo.
          </span>
          <span v-if="selectedStatus === 'pending'">¡Buenísimo! No tienes pedidos pendientes.</span>
          <span v-if="selectedStatus === 'confirmed'">¡Espectacular!, No tienes pedidos pendientes de despacho.</span>
          <span v-if="selectedStatus === 'deliveryInProgress'">No tienes pedidos en despacho.</span>
          <span v-if="selectedStatus === 'delivered'">
            Aún no tienes pedidos despachados. Pero sabemos que pronto tendrás.
          </span>
          <span v-if="selectedStatus === 'incidence'">
            ¡Genial! No tienes pedidos con incidencias.
          </span>
        </p>
        <pagination-component
          v-if="!isLoading && subOrders?.length > 0"
          :current-page="currentPage"
          :total-pages="totalPages"
          @set-new-page="(newPage: number) => currentPage = newPage"
        />
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue';
import { omit, values } from 'lodash';
import { useQuery } from 'vue-query';
import subOrdersApi from 'api/sub-orders';
import useSubOrdersQuery from './queries/sub-orders-query';
import SubOrderListCard from './sub-order-list-card.vue';
import PaginationComponent from './shared/pagination-component.vue';
import lokalSingleSelector from './shared/lokal-single-selector.vue';
import SubOrderListDownloads from './sub-order-list-downloads.vue';
import FormInput from './shared/form-input.vue';
import useShopPickupConditionQuery from './queries/shop-pickup-condition-query';

const STATUSES_HASH = {
  all: 0, pending: 1, confirmed: 2, deliveryInProgress: 3, delivered: 4, incidence: 5, readyForPickup: 6,
};
const statusHash = ref({ pending: 'Pendientes', confirmed: 'Despacho pendiente', readyForPickup: 'Retiro pendiente',
  deliveryInProgress: 'En despacho', delivered: 'Entregados', incidence: 'Incidencias', all: 'Todos',
});

interface SubOrderListProps {
  maker: Maker,
  totalWholesaleAmount?: number,
  totalWholesaleAmountWithoutCommission?: number,
  averageWholesaleTicket?: number,
  averageWholesaleTicketWithoutCommission?: number,
  totalShopsReached?: number,
  totalProductsSold?: number,
  withMetrics?: boolean,
  withRecomedationLink?: boolean,
}

const props = withDefaults(defineProps<SubOrderListProps>(), {
  withMetrics: true,
  withRecomedationLink: true,
  totalWholesaleAmount: 0,
  totalWholesaleAmountWithoutCommission: 0,
  averageWholesaleTicket: 0,
  averageWholesaleTicketWithoutCommission: 0,
  totalShopsReached: 0,
  totalProductsSold: 0,
});

const isMobile = inject('isMobile') as boolean;
const isMdScreen = inject('isMdScreen') as boolean;

const selectedStatus = ref('pending' as keyof typeof STATUSES_HASH);
const currentPage = ref(1);
function updateSelectedStatus(status: keyof typeof STATUSES_HASH) {
  selectedStatus.value = status;
  currentPage.value = 1;
}
const searchText = ref('');
const searchTextParam = computed(() => {
  if (searchText.value.match(/^\d+$/)) {
    return `&q[id_eq]=${searchText.value}`;
  }

  return `&q[order_shop_name_i_cont]=${searchText.value}`;
});

const queryParams = computed(() => {
  let params = 'q[s]=pending_at+desc';
  if (searchText.value) params += searchTextParam.value;
  if (selectedStatus.value !== 'all') {
    if (selectedStatus.value === 'incidence') {
      params += '&q[incidence_status_eq]=1';
    } else {
      params += `&q[status_eq]=${STATUSES_HASH[selectedStatus.value]}`;
    }
  }

  return params;
});

const { subOrdersQuery, isSubOrderQueryLoading, subOrders, totalPages, totalResources } = useSubOrdersQuery(
  computed(() => props.maker.id), currentPage, queryParams,
);
const isLoading = computed(() => isSubOrderQueryLoading.value);

const { shopPickupCondition } = useShopPickupConditionQuery(computed(() => props.maker.id));

const filterStatusHash = computed(() => {
  if (shopPickupCondition.value?.active) return statusHash.value;

  return omit(statusHash.value, ['readyForPickup']);
});

const subOrderStautsesQuery = useQuery(['sub-orders-group-by-status', props.maker],
  () => subOrdersApi.groupByStatus(props.maker.id), { keepPreviousData: true });
const subOrderStatusIsLoading = computed(() => subOrderStautsesQuery.isLoading.value);
const subOrderStatuses = computed(() => subOrderStautsesQuery.data.value?.data?.data);
const totalSubOrdersCount = computed(() => values(subOrderStatuses.value).reduce((acc, value) => acc + value, 0));

const subOrderIncidenceStatuseQuery = useQuery(['sub-orders-incidence-status', props.maker],
  () => subOrdersApi.groupByIncidenceStatus(props.maker.id), { keepPreviousData: true });
const subOrderIncidenceStatusIsLoading = computed(() => subOrderIncidenceStatuseQuery.isLoading.value);
const subOrderIncidenceStatuses = computed(() => subOrderIncidenceStatuseQuery.data.value?.data?.data);

const statusOptionRecords = computed(() =>
  Object.entries(statusHash.value).map(([key, value]) => {
    let counter;
    if (key === 'all') {
      counter = totalSubOrdersCount.value;
    } else if (key === 'incidence') {
      counter = subOrderIncidenceStatuses.value?.incidenceInProgress;
    } else {
      counter = subOrderStatuses.value?.[key];
    }

    return { id: key, name: `${value} (${counter || 0})` };
  }),
);
const selectedStatusRecord = ref({ id: 'all', name: 'Todos' });
watch(selectedStatusRecord, (newSelectedStatusRecord) => {
  updateSelectedStatus(newSelectedStatusRecord.id as keyof typeof STATUSES_HASH);
});

async function reloadSubOrders() {
  await Promise.all([
    subOrdersQuery.refetch.value(),
    subOrderStautsesQuery.refetch.value(),
    subOrderIncidenceStatuseQuery.refetch.value(),
  ]);
}
</script>
