<template>
  <div
    v-if="menuStore.showMenu"
    class="fixed inset-0 z-20 bg-gray-800/50"
    aria-hidden="true"
    @click="menuStore.showMenu = false"
  />
  <transition
    enter-active-class="transition-transform duration-300"
    enter-from-class="-translate-x-full sm:translate-x-full"
    enter-to-class="translate-x-0"
    leave-active-class="transition-transform duration-300"
    leave-from-class="translate-x-0"
    leave-to-class="-translate-x-full sm:translate-x-full"
  >
    <nav
      v-if="menuStore.showMenu"
      id="sidebar"
      class="flex fixed left-0 z-30 flex-col w-3/5 h-full text-sm bg-white md:right-0 md:left-auto md:w-60 md:text-base"
    >
      <div class="flex sticky top-0 flex-col w-full">
        <template v-if="isMobile">
          <a
            class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
            href="/"
          >
            Inicio
          </a>
          <a
            class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
            href="/makers"
          >
            Marcas
          </a>
          <a
            class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
            href="/categories"
          >
            Categorías
          </a>
          <a
            class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
            href="/products"
          >
            Productos
          </a>
          <a
            class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
            href="/about_us"
          >
            Sobre Nosotros
          </a>
        </template>
        <template v-if="!sessionStore.user?.id">
          <button
            class="py-2 px-3 text-left hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
            @click="menuStore.openUserRegisterDialog(true)"
          >
            Iniciar Sesión
          </button>
          <button
            class="py-2 px-3 text-left hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
            @click="menuStore.openUserRegisterDialog(false)"
          >
            Crear Cuenta
          </button>
        </template>
        <the-sidebar-shops v-else-if="sessionStore.user?.id && sessionStore.user?.shopId" />
        <template v-else-if="sessionStore.user?.id && sessionStore.user?.makerIds?.[0]">
          <a
            class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
            href="/makers/account"
          >
            Mi cuenta
          </a>
          <a
            class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
            href="/makers/account?tab=refer"
          >
            Invita a tiendas
          </a>
        </template>
        <div
          v-if="sessionStore.user?.id"
          class="flex fixed bottom-0 flex-col w-3/5 border-t border-gray-300 md:w-60"
        >
          <div class="flex justify-center items-center py-3 w-full md:py-4 md:px-5">
            <UserCircleIcon class="mr-2 w-8 h-8 text-gray-700" />
            <p class="flex flex-col text-sm">
              <span>{{ sessionStore.user?.firstName }}</span>
              <span
                v-if="sessionStore.user?.id && sessionStore.user?.shopId"
                class="text-xs font-light"
              >
                {{ shopOrMakerName }}
              </span>
            </p>
          </div>
          <button
            class="py-3 px-5 w-full text-sm text-center hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-4"
            @click="resetStoresAndSignOut"
          >
            Salir
          </button>
        </div>
      </div>
    </nav>
  </transition>
</template>
<script lang="ts" setup>
import { inject, computed } from 'vue';
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import useGeneralMenuStore from 'stores/general-menu-store';
import useSessionStore from 'stores/user-store';
import useCartStore from 'stores/cart';
import useSignUpStore from 'stores/sign-up-store';
import useShopQuery from './queries/shop-query';
import useMakerQuery from './queries/maker-query';
import theSidebarShops from './the-sidebar-shops.vue';

const isMobile = inject('isMobile');

const menuStore = useGeneralMenuStore();
const sessionStore = useSessionStore();
const cartStore = useCartStore();
const signUpStore = useSignUpStore();

const shopId = computed(() => sessionStore.user?.shopId);
const { shop } = useShopQuery(shopId);
const makerId = computed(() => sessionStore.user?.makerIds?.[0]);
const { maker } = useMakerQuery(makerId);

function resetStoresAndSignOut() {
  sessionStore.reset();
  cartStore.reset();
  signUpStore.reset();
  window.location.href = '/users/sign_out';
}

const shopOrMakerName = computed(() => {
  if (sessionStore.user?.id && sessionStore.user?.shopId) {
    return shop.value?.name;
  }
  if (sessionStore.user?.id && sessionStore.user?.makerIds?.[0]) {
    return maker.value?.name;
  }

  return '';
});
</script>
