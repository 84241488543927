<template>
  <div class="flex flex-col">
    <button
      class="flex justify-between items-center p-3 w-full"
      @click="showMetrics = !showMetrics"
    >
      <p class="text-base md:text-lg">
        Ventas por región
      </p>
      <ChevronDownIcon
        class="w-5 h-5 md:w-6 md:h-6"
        :class="{ 'rotate-180': showMetrics }"
      />
    </button>
    <template v-if="showMetrics">
      <lokal-loading
        v-if="destinationMetricsLoading"
        :loading="destinationMetricsLoading"
      />
      <div
        v-else
        class="px-3"
      >
        <div class="flex flex-col mb-2 text-sm rounded border md:text-base">
          <div class="grid grid-cols-4 p-3 bg-gray-50">
            <p class="col-span-3">
              Región
            </p>
            <p class="place-self-center">
              Monto
            </p>
          </div>
          <div
            v-for="groupedWholesaleAmount, regionName in groupedWholesaleAmounts"
            :key="regionName"
            class="grid grid-cols-4 py-1 px-3"
          >
            <p class="col-span-3">
              {{ regionName }}:
            </p>
            <p class="place-self-center">
              {{ useFormatCurrency(groupedWholesaleAmount) }}
            </p>
          </div>
        </div>
        <pie-chart
          v-if="showMetrics"
          :id="`chart-wholesale-amount`"
          class="flex justify-center items-center w-full h-[450px] "
          :labels="wholesaleAmountLabels"
          :datasets="wholesaleAmountDatasets"
          :options="{ responsive: true, maintainAspectRatio: true, plugins: wholesaleAmountPlugins }"
        />
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { groupBy, keys, values, sumBy } from 'lodash';
import PieChart from './shared/pie-chart.vue';
import regionColorsJson from '../files/region_colors.json';
import useFormatCurrency from './use/format-currency';
import useDestinationMetricsQuery from './queries/destination-metrics-query';

const props = defineProps<{
  maker: Maker
  queryParams: string
}>();

const showMetrics = ref(false);
const colorRegions = ref(regionColorsJson.regions as Record<string, string>);
const computedMakerId = computed(() => props.maker.id);
const computedShowMetrics = computed(() => showMetrics.value);
const computedQueryParams = computed(() => props.queryParams);
const {
  destinationMetricsLoading, subOrders,
} = useDestinationMetricsQuery(computedMakerId, computedShowMetrics, computedQueryParams);
const groupedSubOrders = computed(() => groupBy(subOrders.value, 'destinationAddress.regionName'));

function getTotalWholesaleAmountWithoutDiscounts(sumSubOrders: SubOrder[]) {
  return sumBy(
    sumSubOrders,
    (subOrder) =>
      subOrder.totalWholesaleAmountCents - subOrder.volumeDiscountAmountCents -
        subOrder.totalDiscountAmountCents - subOrder.totalDiscountCodeAmountCents,
  );
}

const wholesaleAmountLabels = computed(() => values(groupedSubOrders.value).map((_subOrders) =>
  `${_subOrders[0].destinationAddress?.regionName}`));
const groupedWholesaleAmounts = computed(() => {
  const data = {} as Record<string, number>;
  keys(groupedSubOrders.value).forEach((regionName: string) => {
    data[regionName] = getTotalWholesaleAmountWithoutDiscounts(groupedSubOrders.value[regionName]);
  });

  return data;
},
);
const wholesaleAmountDatasets = computed(() => [{
  backgroundColor: values(groupedSubOrders.value).map((_subOrders) =>
    colorRegions.value[_subOrders[0]?.destinationAddress?.regionCode as string]),
  data: values(groupedWholesaleAmounts.value),
}]);
const wholesaleAmountPlugins = computed(() => ({
  tooltip: {
    callbacks: {
      label: (context: Record<string, any>) => useFormatCurrency(context.parsed),
    },
  },
}));
</script>
