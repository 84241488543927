<template>
  <div class="flex flex-col">
    <p class="mb-1 text-base font-semibold md:text-lg">
      Información bancaria
    </p>
    <div class="mb-2 w-full border-b border-gray-700" />
    <p
      v-if="!billingInformation?.id"
      class="text-sm font-light md:text-base"
    >
      Debes completar la información de facturación antes de agregar la información bancaria.
    </p>
    <form
      v-else
      @submit="onSubmit"
    >
      <p class="text-sm md:text-base">
        Banco
      </p>
      <div
        v-if="banksIsLoading"
        class="w-full h-10 rounded-md border"
      >
        <div class="h-full bg-[length:200%_100%] bg-gradient-to-r from-gray-200 via-white to-gray-200 rounded-md animate-shimmer" />
      </div>
      <lokal-generic-combobox
        v-else
        v-model="bank"
        :options-label="`Seleccione un banco`"
        class="font-light"
        :class="{ 'border-red-500 border rounded-md': errors.bank }"
        :multiple="false"
        compare-by="id"
        :local-search="false"
        :options="banks"
        :get-option-key="(bank: Bank) => bank.id"
        :get-option-label="(bank: Bank) => bank?.id ? `${bank.name}` : ''"
      />
      <div
        v-if="banksIsLoading"
        class="w-full h-10 rounded-md border"
      >
        <div class="h-full bg-[length:200%_100%] bg-gradient-to-r from-gray-200 via-white to-gray-200 rounded-md animate-shimmer" />
      </div>
      <Field
        v-else
        v-model="bankId"
        name="bank"
        hidden
        :rules="{ required: true }"
      />
      <ErrorMessage
        name="bank"
        class="text-xs text-red-500 md:text-sm"
      />
      <p class="text-sm md:text-base">
        Número de cuenta
      </p>
      <div
        v-if="banksIsLoading"
        class="w-full h-10 rounded-md border"
      >
        <div class="h-full bg-[length:200%_100%] bg-gradient-to-r from-gray-200 via-white to-gray-200 rounded-md animate-shimmer" />
      </div>
      <Field
        v-else
        v-model="account"
        name="account"
        :rules="{ required: true }"
        class="p-1.5 w-full text-sm font-light rounded-md border border-lk-light-gray focus:border-lk-green focus:outline-none md:text-base"
      />
      <ErrorMessage
        name="account"
        class="text-xs text-red-500 md:text-sm"
      />
      <p class="text-sm md:text-base">
        Tipo de cuenta
      </p>
      <lokal-generic-combobox
        v-model="accountType"
        :options-label="`Seleccione un tipo de cuenta`"
        class="mb-2 font-light"
        :class="{ 'border-red-500 border rounded-md': errors.accountType }"
        :multiple="false"
        compare-by="id"
        :local-search="false"
        :options="accountTypes"
        :get-option-key="(accountType: AccountType) => accountType.id"
        :get-option-label="(accountType: AccountType) => accountType.name"
      />
      <Field
        v-model="accountType"
        name="accountType"
        hidden
        :rules="{ required: true }"
      />
      <ErrorMessage
        name="accountType"
        class="text-xs text-red-500 md:text-sm"
      />
      <lokal-loader-button
        v-model="updateBillingInformationIsLoading"
        action-text="Actualizar"
        loading-text="Actualizando..."
        type="submit"
        class="flex justify-center items-center p-2 w-full max-w-[12rem] text-xs hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
        :class="{ 'bg-lk-green text-white': updateBillingInformationIsLoading }"
      />
    </form>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useMutation } from 'vue-query';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import billingInformationsApi from 'api/billing-informations';
import useMessageStore from 'stores/message-store';
import useBanksQuery from './queries/banks-query';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import lokalLoaderButton from './shared/lokal-loader-button.vue';

interface AccountType {
  id: string;
  name: string;
}

const props = defineProps<{
  maker: Maker;
  billingInformation: BillingInformation;
}>();

const messageStore = useMessageStore();

const { handleSubmit, errors } = useForm();

const accountTypes = computed(() => [
  { name: 'Cuenta corriente', id: 'current_account' },
  { name: 'Cuenta vista', id: 'cash_account' },
  { name: 'Cuenta de ahorro', id: 'savings_account' },
] as AccountType[]);

const bank = ref({} as Bank);
const bankId = computed(() => bank.value?.id);
const account = ref(props.billingInformation?.account);
const accountType = ref({} as AccountType);

const billingInformationPresent = computed(() => !!props.billingInformation?.id);
const { banks, isLoading: banksIsLoading, banksQuery } = useBanksQuery(billingInformationPresent);
watch(banks, (newValue: Bank[], oldValue: Bank[]) => {
  if (newValue?.length && newValue.length > 0 && !oldValue?.length) {
    const foundBank = banks.value.find((internalBank: Bank) => internalBank.id === props.billingInformation?.bankId);
    if (foundBank) bank.value = foundBank;
  }
});

onMounted(async () => {
  await banksQuery.refetch.value();
  if (props.billingInformation?.bankId) {
    const foundBank = banks.value.find((internalBank: Bank) => internalBank.id === props.billingInformation?.bankId);
    if (foundBank) bank.value = foundBank;
    const foundAccountType = accountTypes.value.find((internalAccountType: AccountType) =>
      internalAccountType.name === props.billingInformation.accountType);
    if (foundAccountType) accountType.value = foundAccountType;
  }
});

const emit = defineEmits<{(e: 'reload:billingInformation'): void}>();
const updateBillingInformationMutation = useMutation(
  () =>
    billingInformationsApi.update(props.billingInformation.id, {
      bankId: bank.value.id,
      account: account.value,
      accountType: accountType.value.name,
    }),
  {
    onSuccess: () => {
      const message = 'Datos bancarios actualizados correctamente';
      messageStore.showMessage(message, 'success');
      emit('reload:billingInformation');
    },
  },
);
const updateBillingInformationIsLoading = computed(() => updateBillingInformationMutation.isLoading.value);

const onSubmit = handleSubmit(() => {
  updateBillingInformationMutation.mutate();
});
</script>
