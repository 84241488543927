<template>
  <div v-if="!addressesQueryLoading">
    <p>
      Dirección:
    </p>
    <lokal-generic-combobox
      v-model="selectedAddress"
      :options="addresses"
      :get-option-key="(option: Address) => option.id"
      :get-option-label="(option: Address) => option?.id ? `${option.nameReference}: ${option.address}` : ''"
      :options-label="`Selecciona una dirección`"
      :multiple="false"
      class="mb-2"
    />
    <div class="flex items-center mb-2">
      <p class="mr-2">
        Peso (kg):
      </p>
      <Field
        v-model="weight"
        class="p-2 rounded-md border"
        name="weight"
      />
    </div>
    <div v-if="selectedAddress && weight">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <p class="mb-3">
          Resultado:
        </p>
        <div
          class="grid grid-cols-6 font-semibold"
        >
          <span>Carrier</span>
          <span>Nombre del carrier</span>
          <span>Nombre del servicio</span>
          <span>Tipos de servicios</span>
          <span>Días de tránsito</span>
          <span>Precio</span>
        </div>
        <div
          v-for="shippingQuotation in sortedShippingQuotations"
          :key="shippingQuotation.name"
        >
          <div
            v-for="service in shippingQuotation.services"
            :key="`${service.name}-${shippingQuotation.name}`"
            class="grid grid-cols-6"
          >
            <span>{{ shippingQuotation.carrier }}</span>
            <span>{{ shippingQuotation.name }}</span>
            <span>{{ service.name }}</span>
            <span>{{ service.serviceTypes }}</span>
            <span>{{ service.transitDays }}</span>
            <span>{{ service.priceFormatted }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { Field } from 'vee-validate';
import useShippingQuotationsQuery from './queries/shipping-quotations-query';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import useAddressesQuery from './queries/addresses-query';

const DEFAULT_WEIGHT = 3;

const props = defineProps<{
  maker: Maker,
  commune: Commune
}>();

const { addresses, addressesQuery } = useAddressesQuery(
  computed(() => props.maker.id), computed(() => 'Maker'));
const addressesQueryLoading = computed(() => addressesQuery.isLoading.value);
const selectedAddress = ref({} as Address);
const weight = ref(DEFAULT_WEIGHT);
const originCommune = computed(() => selectedAddress.value?.communeName as string);
const destinationCommune = computed(() => props.commune.name);
const currentWeight = computed(() => weight.value);
const enabledShippingQuotationsQuery = computed(() => !!selectedAddress.value?.id);
const computedMakerId = computed(() => props.maker.id);
const { sortedShippingQuotations, isLoading } = useShippingQuotationsQuery(
  originCommune, destinationCommune, currentWeight, enabledShippingQuotationsQuery, computedMakerId,
);

watch(() => props.maker, () => {
  selectedAddress.value = {} as Address;
});
</script>
