import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(fromPlace: string, toPlace: string, weight: number, makerId?: number) {
    let path = '/api/internal/enviame/shipping_quotations?';
    path += `from_place=${fromPlace}&to_place=${toPlace}&weight=${weight}`;
    if (makerId) path += `&maker_id=${makerId}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  carriers(queryParams = '') {
    return api({
      method: 'get',
      url: `/api/internal/enviame/carriers?${queryParams}`,
      headers,
    });
  },
  validate(constrainableId: number, constrainableType: string, communeId?: number) {
    const constrainables = `constrainable_id=${constrainableId}&constrainable_type=${constrainableType}`;
    const path = `/api/internal/enviame/validate?&${constrainables}&commune_id=${communeId}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  bluexQuotation(fromPlace: string, toPlace: string, weight: number) {
    return api({
      method: 'get',
      url: `/api/internal/bluex_quotation?from_place=${fromPlace}&to_place=${toPlace}&weight=${weight}`,
      headers,
    });
  },
};
