<template>
  <div class="flex flex-col items-center px-3 mb-20 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <h2 class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Información de tu tienda
      </h2>
    </div>
    <div
      class="flex flex-col py-3 px-2 w-full h-full bg-white rounded-md md:py-5 md:px-4 lg:px-10"
    >
      <form
        class="flex flex-col w-full text-gray-700"
        @submit="onSubmit"
      >
        <custom-input
          v-model="form.name"
          name="name"
          title="Nombre"
          label="Nombre de la tienda"
          placeholder="Nombre"
          class="mb-2"
          :rules="{ required: true }"
        />
        <custom-input
          v-model="form.businessRut"
          name="businessRut"
          title="Rut de la empresa"
          label="Rut de la empresa"
          placeholder="Rut de la empresa"
          class="mb-2"
          :rules="{ required: true, rut: true }"
        />
        <custom-input
          v-model="form.commercialBusiness"
          name="commercialBusiness"
          title="Giro comercial"
          label="Giro comercial"
          placeholder="Giro comercial"
          class="mb-2"
          :rules="{ required: true }"
        />
        <custom-input
          v-model="form.socialReason"
          name="socialReason"
          title="Razón social"
          label="Razón social"
          placeholder="Razón social"
          class="mb-2"
          :rules="{ required: true }"
        />
        <custom-input
          v-model="form.contactName"
          name="contactName"
          title="Nombre de contacto"
          label="Nombre de contacto"
          placeholder="Nombre de contacto"
          class="mb-2"
          :rules="{ required: true }"
        />
        <custom-input
          v-model="form.contactNumber"
          name="contactNumber"
          title="Teléfono de contacto"
          label="Teléfono de contacto"
          placeholder="Teléfono de contacto"
          class="mb-2"
          :rules="{ required: true }"
        />
        <custom-input
          v-model="form.contactEmail"
          name="contactEmail"
          title="Email de contacto"
          label="Email de contacto"
          placeholder="Email de contacto"
          class="mb-2"
          :rules="{ required: true }"
        />
        <custom-input
          v-model="form.financeEmail"
          name="financeEmail"
          title="Email de finanzas"
          label="Email de finanzas"
          placeholder="Email de finanzas"
          class="mb-2"
        />
        <custom-input
          v-model="form.webpageLink"
          name="webpageLink"
          title="Link de la página web"
          label="Link de la página web"
          placeholder="Link de la página web"
          class="mb-2"
        />
        <p class="text-sm font-light md:text-base">
          Tipo de tienda
        </p>
        <div
          class="flex flex-col"
          :class="{ 'mb-4': selectedKind?.id !== 3 }"
        >
          <lokal-generic-combobox
            v-model="selectedKind"
            :options="shopKindOptions"
            :get-option-key="(option: Record<string, string>) => option.id"
            :get-option-label="(option: Record<string, string>) => option.name"
            key-option="type"
            option-label="name"
            :options-label="`Selecciona una comuna de origen`"
            :multiple="false"
            class="font-light"
          />
          <form-input
            v-model="kind"
            class="hidden"
            placeholder="Tipo de tienda"
            type="text"
            name="shops[kind]"
            label="Tipo de tienda"
            :rules="{ required: true }"
          />
          <p
            v-if="selectedKind.id === 2"
            class="text-sm"
          >
            Lokal es una plataforma para abastecer tiendas. Si deseas comprar
            para consumo personal debes hacerlo en la página de cada marca.
          </p>
          <ErrorMessage
            name="shops[kind]"
            class="text-sm text-red-500"
          />
        </div>
        <div
          v-if="selectedKind?.id === 3"
          class="mt-2 mb-4"
        >
          <custom-input
            v-model="otherKind"
            placeholder="Otro tipo de tienda"
            type="text"
            name="shops[other_kind]"
            label="Otro tipo de tienda"
            :rules="{ required: true }"
          />
        </div>
        <lokal-loader-button
          v-model="shopUpdateMutationIsLoading"
          action-text="Actualizar"
          loading-text="Actualizando..."
          type="submit"
          class="flex justify-center items-center p-2 w-full max-w-[12rem] text-xs hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
          :class="{ 'bg-lk-green text-white': shopUpdateMutationIsLoading }"
        />
      </form>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { ErrorMessage, useForm } from 'vee-validate';
import { useMutation } from 'vue-query';
import shopsApi from 'api/shops';
import useMessageStore from 'stores/message-store';
import formInput from './shared/form-input.vue';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import customInput from './shared/custom-input.vue';
import lokalLoaderButton from './shared/lokal-loader-button.vue';

interface ShopInformationProps {
  shopId: number
  shop: Shop
}

const props = defineProps<ShopInformationProps>();

const messageStore = useMessageStore();

const shopKindOptions = ref([
  { id: 4, name: 'Tienda física y online', type: 'physical_and_online_store' },
  { id: 0, name: 'Solo tienda física', type: 'physical_store' },
  { id: 1, name: 'Solo tienda online', type: 'online_store' },
  { id: 2, name: 'Consumo personal', type: 'personal_consumption' },
  { id: 3, name: 'Otro', type: 'other' },
]);
const selectedKind = ref(
  shopKindOptions.value.find((option) => props.shop?.kind === option.type) || {} as Record<string, string>);
const kind = computed(() => selectedKind.value?.name as string);
const otherKind = ref(props.shop?.id ? props.shop?.otherKind : '');
const form = ref({
  name: props.shop.name,
  commercialBusiness: props.shop.commercialBusiness,
  businessRut: props.shop.businessRut,
  socialReason: props.shop.socialReason,
  contactName: props.shop.contactName,
  contactNumber: props.shop.contactNumber,
  contactEmail: props.shop.contactEmail,
  financeEmail: props.shop.financeEmail,
  webpageLink: props.shop.webpageLink,
  kind: props.shop.kind,
  otherKind: props.shop.otherKind,
});

const shopUpdateMutation = useMutation(
  () => {
    const otherKindNumber = 3;
    const data = { ...form.value, kind: selectedKind.value?.type };
    if (selectedKind.value?.id === otherKindNumber) data.otherKind = otherKind.value;
    if (!!otherKind.value && selectedKind.value?.id !== otherKindNumber) data.otherKind = '';

    return shopsApi.update(props.shopId, data);
  },
  {
    onSuccess: () => {
      messageStore.showMessage('Tienda actualizada correctamente', 'success');
    },
  },
);
const shopUpdateMutationIsLoading = computed(() => shopUpdateMutation.isLoading.value);

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  shopUpdateMutation.mutate();
});
</script>
