<template>
  <div class="mb-10 flex size-full flex-col items-center text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="mb-3 flex w-full items-center px-2 md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Métricas de rotación</span>
      </p>
      <downloadable-files-form
        :action="`/makers/${maker.id}/shops_rotation`"
        :classes="classes"
        class="ml-auto"
      >
        <template #custom-label>
          <ArrowDownTrayIcon class="mr-1 size-4 shrink-0 md:mr-2 md:size-5" />
          <span class="">Descargar</span>
        </template>
      </downloadable-files-form>
    </div>
    <div class="flex size-full flex-col rounded-md bg-white p-3 sm:p-4 md:p-5 lg:p-6 xl:p-8">
      <lokal-loading
        v-if="isShopsQueryLoading"
        :loading="isShopsQueryLoading"
      />
      <div
        v-else
        class="flex w-full flex-col overflow-x-auto"
      >
        <table
          class="border text-sm md:text-base"
        >
          <thead class="border">
            <tr class="text-left">
              <th class="p-2">
                Tienda
              </th>
              <th class="hidden p-2 md:block">
                Razón social
              </th>
              <th class="text-center">
                Pedidos
              </th>
              <th class="text-center">
                Monto último pedido
              </th>
              <th class="text-center">
                Último pedido
              </th>
              <th class="text-center">
                Monto promedio
              </th>
              <th class="text-center">
                Tiempo entre pedidos
              </th>
            </tr>
          </thead>
          <tbody class="">
            <maker-shop-metrics-list-card
              v-for="shop in shops"
              :key="`metrics-list-${shop.id}`"
              :shop="shop"
              :maker="maker"
            />
          </tbody>
        </table>
        <pagination-component
          v-if="!isShopsQueryLoading && shops.length > 0"
          :total-pages="totalPages"
          :current-page="currentPage"
          @set-new-page="updatePage"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useQuery } from 'vue-query';
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline';
import shopsApi from 'api/shops';
import makerShopMetricsListCard from 'components/maker-shop-metrics-list-card.vue';
import useCustomParseInt from './use/custom-parse-int';
import paginationComponent from './shared/pagination-component.vue';
import DownloadableFilesForm from './shared/downloadable-files-form.vue';

interface MakerShopMetricsListProps {
  maker: Maker;
}

const props = defineProps<MakerShopMetricsListProps>();

const currentPage = ref(1);
const shopQueryOptions = computed(() => ({
  makerId: props.maker.id,
  pageNumber: currentPage.value,
}));
const shopQueryPrams = computed(() => `page[number]=${shopQueryOptions.value.pageNumber}&page[size]=24`);
const shopsQuery = useQuery(
  ['shops', shopQueryOptions],
  () => shopsApi.index(props.maker.id, shopQueryPrams.value),
  { keepPreviousData: true },
);
const isShopsQueryLoading = computed(() => shopsQuery.isLoading.value || shopsQuery.isPreviousData.value);
const shops = computed(() => shopsQuery.data.value?.data?.shops as Shop[]);
const totalResources = computed(() => useCustomParseInt(shopsQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(shopsQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

function updatePage(newPage: number) {
  currentPage.value = newPage;
}

const classes = computed(() =>
  `flex items-center p-1 ml-auto text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md
  border border-lk-green md:p-2 md:text-base bg-white`);
</script>
