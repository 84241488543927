<template>
  <div
    v-if="isLoading"
    class="w-full bg-lk-light-gray p-1 text-center text-sm text-white md:p-1.5 md:text-base"
    :class="{'rounded-b-md': !inProductOrOrdersPath,
             'rounded-md': inProductOrOrdersPath,}"
  >
    Cargando datos...
  </div>
  <div
    v-else-if="!validDestination"
    class="w-full bg-lk-light-gray p-1 text-center text-sm text-white md:p-1.5 md:text-base"
    :class="{'rounded-b-md': !inProductOrOrdersPath, 'rounded-md': inProductOrOrdersPath}"
  >
    No disponible a tu dirección
  </div>
  <product-out-of-stock-button
    v-else-if="productOutOfStock"
    :product="product"
    @show-register-dialog="emit('show-register-dialog')"
  />
  <a
    v-else-if="props.variantNeeded && !inProductOrOrdersPath"
    :href="`/products/${product.id}`"
    class="w-full rounded-b-md bg-lk-light-gray p-1 text-center text-sm text-white hover:bg-lk-green md:p-1.5 md:text-base"
  >
    Seleccionar opción
  </a>
  <button
    v-else-if="!sessionStore.user?.id && !inProductOrOrdersPath"
    class="w-full rounded-b-md bg-lk-light-gray p-1 text-sm text-white hover:bg-lk-green md:p-1.5 md:text-base"
    :class="{ 'rounded-b-md': !inProductOrOrdersPath, 'rounded-md md:w-1/2': inProductOrOrdersPath }"
    :disabled="variantNeededAndNotPresent"
    @click="handleInitialShopping"
  >
    Revisar producto
  </button>
  <button
    v-else-if="!cartStore.itemPresent(product.id) || variantNeededAndNotPresent"
    class="w-full rounded-b-md bg-lk-light-gray p-1 text-sm text-white md:p-1.5 md:text-base"
    :class="{
      'cursor-not-allowed': variantNeededAndNotPresent,
      'hover:bg-lk-green': !variantNeededAndNotPresent,
      'rounded-b-md': !inProductOrOrdersPath,
      'rounded-md md:w-1/2': inProductOrOrdersPath,
    }"
    :disabled="variantNeededAndNotPresent"
    @click="handleInitialShopping"
  >
    Agregar al carro
  </button>
  <div
    v-else
    class="flex w-full items-center"
    :class="{ 'md:w-1/2': inProductPath }"
  >
    <button
      class="flex-1 rounded-bl-md bg-lk-green p-1 text-white md:p-1.5"
      :class="{ 'rounded-l-md md:p-2': inProductOrOrdersPath, 'opacity-30': cartProductMutationIsLoading }"
      :disabled="quantity === 0 || cartProductMutationIsLoading"
      @click="removeOneUnit"
    >
      -
    </button>
    <Field
      v-if="product.packUnits === 1"
      v-model.number="quantityField"
      :label="product.name"
      :name="`quantity-${product.id}`"
      class="w-8 flex-1 bg-white p-1 text-center text-sm text-lk-green focus:outline-none md:p-1.5 md:text-base"
      :rules="{ asyncStockValidation: product.id }"
      @change="manageQuantityChange"
    />
    <lokal-tooltip
      v-else
      class="w-full flex-1 bg-white p-1 text-lk-green md:p-1.5"
      :main-label="`${quantity}`"
      :information="`Este producto viene en packs de ${product.packUnits} unidades.`"
      button-classes="flex items-center text-sm md:text-base justify-center w-full space-x-1"
      information-classes="w-40 md:w-52"
      arrow-color-classes="border-lk-light-gray"
      information-color-classes="bg-lk-light-gray"
      position-options="center"
    />
    <button
      class="flex-1 rounded-br-md bg-lk-green p-1 text-white md:p-1.5"
      :class="{ 'rounded-r-md md:p-2': inProductOrOrdersPath,
                'opacity-30': increaseButtonDisabled || cartProductMutationIsLoading }"
      :disabled="increaseButtonDisabled || cartProductMutationIsLoading"
      @click="increaseOneUnit"
    >
      +
    </button>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { Field } from 'vee-validate';
import useCartStore from 'stores/cart';
import useSessionStore from 'stores/user-store';
import useCartManager from './use/cart-manager';
import lokalTooltip from './shared/lokal-tooltip.vue';
import ProductOutOfStockButton from './product-out-of-stock-button.vue';
import useValidDestinationQuery from './queries/valid-destination-query';
import useMakerQuery from './queries/maker-query';

interface ProductShoppingButtonProps {
  product: Product,
  variantNeeded?: boolean,
  variant?: Variant
}

const props = withDefaults(defineProps<ProductShoppingButtonProps>(), {
  variantNeeded: false,
  variant: undefined,
});

const productOutOfStock = computed(() =>
  props.product.status === 'out_of_stock' ||
    (props.product.inventory?.enable && props.product.inventory?.quantity === 0));

const computedProduct = computed(() => props.product);
const {
  increaseOneUnit,
  removeOneUnit,
  quantity,
  quantityField,
  manageQuantityChange,
  increaseButtonDisabled,
  cartProductMutationIsLoading,
} = useCartManager(computedProduct, props.variant);

const cartStore = useCartStore();

const emit = defineEmits<{(e: 'show-register-dialog'): void}>();

const sessionStore = useSessionStore();
function handleInitialShopping() {
  if (sessionStore.user?.id) {
    increaseOneUnit();
  } else {
    emit('show-register-dialog');
  }
}

const variantNeededAndNotPresent = computed(() => props.variantNeeded && !props.variant?.id);
const currentPath = computed(() => window.location.pathname);
const inProductOrOrdersPath = computed(() => {
  const regex = /\/products\/[\d]+|\/orders\/(new|[\d]+)/;

  return regex.test(currentPath.value);
});

const inProductPath = computed(() => {
  const regex = /\/products\/[\d]+/;

  return regex.test(currentPath.value);
});

const { maker, makerQuery } = useMakerQuery(computed(() => props.product.makerId));
const currentCommuneId = computed(() => sessionStore.currentAddress.communeId);
const makerDestinationQueryEnabled = computed(() => !!maker.value?.id && maker.value.destinationConstrainsCount > 0);
const computedMakerId = computed(() => props.product.makerId);
const {
  validDestination: validMakerDestination, validDestinationIsLoading: validMakerDestinationIsLoading,
} = useValidDestinationQuery(computedMakerId, computed(() => 'Maker'), currentCommuneId, makerDestinationQueryEnabled);
const computedProductId = computed(() => props.product.id);
const productDestinationQueryEnabled = computed(() => props.product.destinationConstrainsCount > 0);
const {
  validDestination: validProductDestination, validDestinationIsLoading: validProductDestinationIsLoading,
} = useValidDestinationQuery(
  computedProductId, computed(() => 'Product'), currentCommuneId, productDestinationQueryEnabled,
);
const validDestination = computed(() => {
  if (validProductDestination.value !== undefined) return validProductDestination.value;
  else if (validMakerDestination.value !== undefined) return validMakerDestination.value;

  return true;
});

const isLoading = computed(() => makerQuery.isLoading.value || validMakerDestinationIsLoading.value ||
  validProductDestinationIsLoading.value);
</script>
