
import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import shippingQuotationsApi from 'api/shipping-quotations';
import cleanAccents from 'components/use/clean-accents';
import { sortBy, min, round } from 'lodash';

const DEFAULT_WEIGHT = 3;
const MAX_DECIMALS = 2;

// eslint-disable-next-line max-params
export default function useShippingQuotationsQuery(
  originCommuneName: ComputedRef<string>,
  destinationCommuneName: ComputedRef<string>,
  weight = computed(() => DEFAULT_WEIGHT),
  enabledShippingQuotationsQuery = computed(() => true),
  makerId?: ComputedRef<number>,
) {
  const shippingQuotationsQuery = useQuery(
    ['shipping-quotations', originCommuneName, destinationCommuneName, weight, makerId],
    () => {
      const fromPlace = cleanAccents(originCommuneName.value);
      const toPlace = cleanAccents(destinationCommuneName.value as string);
      const currentWeight = round(weight.value, MAX_DECIMALS);

      return shippingQuotationsApi.index(fromPlace, toPlace, currentWeight, makerId?.value);
    },
    { enabled: enabledShippingQuotationsQuery, retry: 2, refetchOnWindowFocus: false },
  );
  const shippingQuotations = computed(() =>
    shippingQuotationsQuery.data.value?.data.shippingQuotations as ShippingQuotation[]);
  const sortedShippingQuotations = computed(() =>
    sortBy(
      shippingQuotations.value,
      (shippingQuotation: ShippingQuotation) => min(shippingQuotation.services.map((service) => service.price)),
    ),
  );
  const isLoading = computed(() => shippingQuotationsQuery.isLoading.value);

  return { shippingQuotationsQuery, shippingQuotations, sortedShippingQuotations, isLoading };
}

