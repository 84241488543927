<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Direcciones</span>
      </p>
      <button
        class="flex items-center p-2 ml-auto text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
        @click="showNewAddressDialog = true"
      >
        <PlusIcon class="mr-1 w-4 h-4 md:mr-2 md:w-5 md:h-5" />
        Agregar una dirección
      </button>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full text-gray-700 bg-white rounded-md md:py-5 md:px-10">
      <lokal-loading
        v-if="addressesQueryIsLoading"
        :loading="addressesQueryIsLoading"
      />
      <p
        v-else-if="addresses.length === 0"
        class="text-sm md:text-base"
      >
        No hay direcciones agregadas.
      </p>
      <div
        v-for="address in addresses"
        v-else
        :key="address.id"
        class="flex flex-col p-2 mb-2 rounded-md border border-lk-light-gray md:p-3"
      >
        <div class="flex items-center">
          <inline-svg-wrapper
            name="warehouse-icon"
            class="shrink-0 mr-1 w-10 h-10 md:w-12 md:h-12"
          />
          <p class="text-base capitalize md:text-lg">
            {{ address.nameReference.toLocaleLowerCase() }}
          </p>
        </div>
        <p class="text-sm md:text-base">
          <span>{{ address.address }}</span>
        </p>
        <p class="text-sm md:text-base">
          <span>{{ address.communeName }}</span>
          <span>, {{ address.regionName }}</span>
        </p>
        <button
          class="flex items-center"
          @click="updateDefaultAddress(address)"
        >
          <div
            class="mr-2 w-4 h-4 rounded-md border border-lk-green"
            :class="{ 'bg-lk-green': maker.defaultAddressId === address.id }"
          />
          <p>
            Dirección por defecto
          </p>
        </button>
      </div>
    </div>
    <address-new-dialog
      v-model="showNewAddressDialog"
      :addressable-id="maker.id"
      :addressable-type="maker.type"
      @update:model-value="(value: boolean) => showNewAddressDialog = value"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/solid';
import { useMutation } from 'vue-query';
import makersApi from 'api/makers';
import useMessageStore from 'stores/message-store';
import useAddressesQuery from './queries/addresses-query';
import inlineSvgWrapper from './inline-svg-wrapper.vue';
import addressNewDialog from './address-new-dialog.vue';

interface MakerAddressesProps {
  maker: Maker;
}

const props = withDefaults(defineProps<MakerAddressesProps>(), {
});

const messageStore = useMessageStore();

const emit = defineEmits<{(event: 'refetch-maker'): void}>();

const showNewAddressDialog = ref(false);
const computedMakerId = computed(() => props.maker.id);
const computedMakerType = computed(() => 'Maker');
const { addresses, addressesQuery } = useAddressesQuery(computedMakerId, computedMakerType);
const addressesQueryIsLoading = computed(() => addressesQuery.isLoading.value);

const updateMakerDefaultAddressMutation = useMutation(
  (address: Address) => makersApi.update(props.maker.id, { defaultAddressId: address.id }), {
    onSuccess: async (data) => {
      const addressId = data.data.maker.defaultAddressId;
      const address = addresses.value.find((internalAddress) => internalAddress.id === addressId);
      await addressesQuery.refetch.value();
      emit('refetch-maker');
      messageStore.kind = 'success';
      messageStore.message = `Dirección por defecto actualizada correctamente a ${address?.address}`;
    },
  },
);

function updateDefaultAddress(address: Address) {
  const addressId = address.id;
  if (props.maker.defaultAddressId === addressId) {
    messageStore.kind = 'error';
    messageStore.message = 'Esta dirección ya es la dirección por defecto';
  } else {
    updateMakerDefaultAddressMutation.mutate(address);
  }
}
</script>
