<template>
  <a
    class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
    href="/shops/account?tab=shop"
  >
    Mi tienda
  </a>
  <a
    class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
    href="/shop_landing"
  >
    Recomendaciones
  </a>
  <a
    href="/shops/account?tab=unpaidOrders"
    class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
  >
    Pendiente de pago
  </a>
  <a
    class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
    href="/shops/account?tab=orders"
  >
    Ordenes
  </a>
  <a
    class="py-2 px-3 hover:text-white hover:bg-lk-green border-t border-gray-300 md:py-3 md:px-4"
    href="/shops/account?tab=subOrders"
  >
    Seguimiento de pedidos
  </a>
</template>
