<template>
  <Combobox
    v-slot="{ open }"
    :model-value="(modelValue as T | T[])"
    :multiple="multiple"
    :disabled="disabled"
    :by="compareBy"
    class="rounded-md border border-lk-light-gray"
    @update:model-value="(value: T | T[]) => emit('update:modelValue', value)"
  >
    <div
      class="flex flex-col w-full"
      :class="[textSize]"
    >
      <!-- Note: the open value is false on click, look docs. -->
      <ComboboxButton @click="emit('combobox:open', !open)">
        <div
          class="flex items-center p-2 bg-white rounded-md"
          :class="{ 'bg-gray-100 opacity-50': disabled }"
        >
          <ComboboxInput
            class="mr-2 w-full focus:outline-none"
            :placeholder="optionsLabel"
            :display-value="() => displayValue"
            autofocus
            @change="searchText = $event.target.value"
          />
          <ChevronDownIcon
            class="w-4 h-4 text-gray-400 md:w-5 md:h-5"
            :class="{ 'transform rotate-180 duration-150 ease-linear': open,
                      'transform rotate-0 duration-150 ease-linear': !open }"
            aria-hidden="true"
          />
        </div>
      </ComboboxButton>
      <div class="relative">
        <transition
          enter-active-class="transition duration-150"
          enter-from-class="-translate-y-2 opacity-50"
          enter-to-class="translate-y-0 transform opacity-100"
          leave-active-class="transition duration-150"
          leave-from-class="translate-y-0"
          leave-to-class="-translate-y-2 transform opacity-0"
        >
          <ComboboxOptions
            class="flex overflow-y-auto absolute left-0 z-10 flex-col mt-2 w-full max-h-96 text-gray-700 bg-white rounded-md border border-lk-light-gray focus:outline-none"
          >
            <template v-if="filteredOptions.length && filteredOptions.length > 0">
              <ComboboxOption
                v-for="option in filteredOptions"
                :key="getOptionKey(option)"
                v-slot="{ active, selected }"
                :value="option"
                as="template"
                @click="updateFilteredOptionsAndSearchText"
              >
                <slot
                  :name="`custom-option-${getOptionKey(option)}`"
                  :active="active"
                  :selected="selected"
                  :option="option"
                  :get-option-label="getOptionLabel"
                  :get-option-key="getOptionKey"
                >
                  <li
                    class="flex items-center p-2 space-x-1 cursor-pointer md:space-x-2"
                    :class="{ 'bg-lk-green text-white': active | selected }"
                  >
                    <CheckIcon
                      class="shrink-0 w-4 h-4 md:w-5 md:h-5"
                      :class="{ 'hidden': !selected, 'text-white': selected }"
                    />
                    <span>{{ getOptionLabel(option) }}</span>
                  </li>
                </slot>
              </ComboboxOption>
            </template>
            <ComboboxOption
              v-else
              class="flex justify-between items-center p-2 cursor-pointer"
            >
              No hay opciones
            </ComboboxOption>
          </ComboboxOptions>
        </transition>
      </div>
    </div>
  </Combobox>
</template>
<script lang="ts" setup generic="T extends string | number | boolean | object | null | undefined">
import { ref, watch, computed, type Ref } from 'vue';
import { debounce } from 'lodash';
import { CheckIcon } from '@heroicons/vue/24/outline';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
} from '@headlessui/vue';

interface LokalGenericComboboxProps {
  options: T[],
  getOptionLabel: (option: T) => string,
  getOptionKey: (option: T) => string | number,
  optionsLabel: string,
  modelValue: T | T[],
  multiple?: boolean,
  localSearch?: boolean,
  textSize?: string,
  compareBy?: string,
  disabled?: boolean
}

const props = withDefaults(defineProps<LokalGenericComboboxProps>(), {
  multiple: false,
  localSearch: true,
  textSize: 'text-sm md:text-base',
  compareBy: 'id',
  disabled: false,
});

const displayValue = computed(() =>
  ((props.modelValue && !Array.isArray(props.modelValue)) ? props.getOptionLabel(props.modelValue) : ''));

const emit = defineEmits<{(e: 'update:modelValue', value: T | T[]): void,
  (e: 'button-pressed'): void, (e: 'update:options'): void,
  (e: 'combobox:open', value: boolean): void }>();

const searchText = ref('');
const filteredOptions = ref(props.options) as Ref<T[]>;

function clearAccents(text: string): string {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function searchForRecords() {
  if (props.localSearch) {
    if (searchText.value.length > 0) {
      const lowerSearchText = clearAccents(searchText.value.toLowerCase());
      filteredOptions.value = props.options.filter((option) => {
        const optionLabel = clearAccents(props.getOptionLabel(option).toLowerCase());

        return optionLabel.includes(lowerSearchText);
      });
    } else {
      filteredOptions.value = props.options;
    }
  } else {
    emit('update:options');
  }
}

const DEBOUNCE_TIME = 200;
const debounceIsTyping = debounce(() => {
  searchForRecords();
}, DEBOUNCE_TIME);
const debounceSearchTextClear = debounce(() => {
  searchText.value = '';
}, DEBOUNCE_TIME);

watch(searchText, () => {
  debounceIsTyping();
});

watch(filteredOptions, () => {
  if (filteredOptions.value.length === 0) {
    debounceSearchTextClear();
  }
});

watch(() => props.options, () => {
  filteredOptions.value = props.options;
});

function updateFilteredOptionsAndSearchText() {
  filteredOptions.value = props.options;
  searchText.value = '';
}
</script>
