<template>
  <button
    :type="type"
    :disabled="disabled"
    @click="emit('update:modelValue', !modelValue)"
  >
    <template v-if="modelValue">
      <loading-spinner class="mr-2 text-gray-200 fill-lk-green" />
      <span>
        {{ loadingText }}
      </span>
    </template>
    <span v-else>
      {{ actionText }}
    </span>
  </button>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import loadingSpinner from './loading-spinner.vue';

interface ActionButtonProps {
  modelValue: boolean;
  disabled?: boolean;
  actionText?: string;
  loadingText?: string;
  action?: 'confirm' | 'cancel' | 'secondary' | 'primary';
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const props = withDefaults(defineProps<ActionButtonProps>(), {
  modelValue: false,
  disabled: false,
  actionText: 'Enviar',
  loadingText: 'Cargando...',
  action: 'confirm',
  type: 'button',
});

const disabled = computed(() => props.disabled || props.modelValue);

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();
</script>
