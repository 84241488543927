<template>
  <tr
    class="border"
  >
    <td class="p-2">
      <a
        class="underline"
        :href="`/makers/${maker.id}/shops/${shop.id}`"
      >
        {{ shop.name }}
      </a>
    </td>
    <td class="hidden p-2 md:block">
      {{ shop.socialReason }}
    </td>
    <td class="text-center">
      <lokal-loading
        v-if="makerShopMetricIsLoading"
        :loading="makerShopMetricIsLoading"
        size="xs"
      />
      <span v-else>
        {{ makerShopMetric.subOrdersCount }}
      </span>
    </td>
    <td class="text-center">
      <lokal-loading
        v-if="makerShopMetricIsLoading"
        :loading="makerShopMetricIsLoading"
        size="xs"
      />
      <span v-else>
        {{ useFormatCurrency(makerShopMetric.lastSubOrderTotalAmount) }}
      </span>
    </td>
    <td class="text-center">
      <lokal-loading
        v-if="makerShopMetricIsLoading"
        :loading="makerShopMetricIsLoading"
        size="xs"
      />
      <span v-else>
        {{ new Date(makerShopMetric.lastSubOrderAt).toLocaleDateString('es-ES') }}
      </span>
    </td>
    <td class="text-center">
      <lokal-loading
        v-if="makerShopMetricIsLoading"
        :loading="makerShopMetricIsLoading"
        size="xs"
      />
      <span v-else>
        {{ useFormatCurrency(makerShopMetric.averageSubOrderTotalAmount) }}
      </span>
    </td>
    <td class="text-center">
      <lokal-loading
        v-if="makerShopMetricIsLoading"
        :loading="makerShopMetricIsLoading"
        size="xs"
      />
      <span v-else-if="makerShopMetric?.timeBetweenSubOrders">
        {{ makerShopMetric.timeBetweenSubOrders }} días
      </span>
      <span v-else>
        -
      </span>
    </td>
  </tr>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import makersApi from 'api/makers';
import useFormatCurrency from './use/format-currency';

const props = defineProps<{
  shop: Shop
  maker: Maker
}>();

const makerShopMetricsQuery = useQuery(
  ['maker-shop-metric', props.maker.id, props.shop.id],
  () => makersApi.shopMetrics(props.maker.id, props.shop.id),
  { enabled: !!props.maker.id && !!props.shop.id },
);
const makerShopMetricIsLoading = computed(() => makerShopMetricsQuery.isLoading.value);
const makerShopMetric = computed(() => makerShopMetricsQuery.data.value?.data?.makerShopMetric as MakerShopMetric);
</script>
