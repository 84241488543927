<template>
  <lokal-loading
    v-if="isLoading"
    :loading="isLoading"
  />
  <bar-chart
    v-else
    class="flex justify-center items-center h-[350px] xl:h-[450px]"
    :chart-id="`chart-bar-metrics-referred-${props.maker.id}`"
    :labels="keys(referredContactsMetrics)"
    :datasets="referredContactsDatasets"
  />
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { keys, sortBy } from 'lodash';
import { useQuery } from 'vue-query';
import { useI18n } from 'vue-i18n';
import referredContactsApi from 'api/referred-contacts';
import barChart from './shared/bar-chart.vue';

const SHOP_KEYS = [
  '',
  'physicalStore',
  'onlineStore',
  'physicalStoreAndOnlineStore',
  'other',
];

const COLORS_BY_KEY = {
  '': '#BCC9C5',
  physicalStore: '#00594C',
  onlineStore: '#9CAF88',
  physicalStoreAndOnlineStore: '#00594C',
  other: '#1B7268',
};

const props = defineProps<{
  maker: Maker;
  initialDate: Date;
  endDate: Date;
}>();

const { t } = useI18n();

const searchParams = computed(() => {
  const params = `q[created_at_gteq]=${props.initialDate}&q[created_at_lteq]=${props.endDate}`;

  return params;
});

const referredContactsMetricsQuery = useQuery(
  ['referredContactsMetrics', props.maker.id, searchParams],
  () => referredContactsApi.metrics(props.maker.id, searchParams.value),
);
const isLoading = computed(() => referredContactsMetricsQuery.isLoading.value);
const referredContactsMetrics = computed(() => referredContactsMetricsQuery.data.value?.data?.metrics);

const orderYearMonth = computed(() => sortBy(keys(referredContactsMetrics.value), ['desc']));
const resultByKey = computed(() => {
  const result = {} as { [key: string]: number[] };
  orderYearMonth.value.forEach((yearMonth: string) => {
    SHOP_KEYS.forEach((key: string) => {
      result[key] = result[key] || [];
      result[key].push(referredContactsMetrics.value[yearMonth]?.[key] || 0);
    });
  });

  return result;
});

const referredContactsDatasets = computed(() => {
  const result = [];
  SHOP_KEYS.forEach((key: string) => {
    result.push({
      label: key ? t(`referredContacts.kind.${key}`) : 'Usuario sin tienda',
      backgroundColor: COLORS_BY_KEY[key],
      data: resultByKey.value?.[key] || [],
      stack: 'group',
    });
  });

  return result;
});
</script>
