import { computed } from 'vue';
import { useQuery } from 'vue-query';
import banksApi from 'api/banks';

export default function useBanksQuery(enabled = computed(() => true)) {
  const banksQuery = useQuery(
    ['banks'],
    () => banksApi.index(),
    { enabled },
  );
  const banks = computed(() => banksQuery.data.value?.data.banks as Bank[]);
  const isLoading = computed(() => banksQuery.isLoading.value);

  return { banks, banksQuery, isLoading };
}
