<template>
  <div class="flex flex-col w-full">
    <div class="flex justify-between items-center w-full text-sm text-gray-700 rounded-md border shadow-sm md:text-base">
      <p class="flex-1 p-2 font-light break-all rounded-sm border-r-0 md:py-3">
        {{ recomendationLink }}
      </p>
      <button
        class="border-l"
        @click="copyToClipBoard"
      >
        <ClipboardDocumentCheckIcon
          v-if="copiedLink"
          class="m-2 w-5 text-lk-green md:m-3 md:w-6"
        />
        <ClipboardDocumentIcon
          v-else
          class="m-2 w-5 md: md:m-3 md:w-6"
        />
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
import useMessageStore from 'stores/message-store';

const props = defineProps<{
  maker?: Maker
  discountCode?: DiscountCode
}>();

const messageStore = useMessageStore();

const copiedLink = ref(false);
const protocol = computed(() => (process.env.APPLICATION_HOST?.includes('http') ? '' : 'https://'));
const host = ref(`${protocol.value}${process.env.APPLICATION_HOST}`);
const recomendationLink = computed(() => {
  const base = `${host.value}/makers/${props.maker?.slug}?referred=${props.maker?.slug}`;

  return props.discountCode?.active ? `${base}&discount_code=${props.discountCode.token}` : base;
});

function copyToClipBoard() {
  navigator.clipboard.writeText(recomendationLink.value).then(() => {
    copiedLink.value = true;
    messageStore.showMessage('Link copiado al portapapeles', 'success', 'short');
  });
}

</script>
