<template>
  <button
    class="flex flex-col w-full"
    @click="showOrderPayments = !showOrderPayments"
  >
    <div class="flex flex-col p-5 mb-3 w-full text-sm bg-gray-50 rounded-md md:text-base">
      <div
        class="flex items-center w-full"
        :class="{ 'mb-2': showOrderPayments }"
      >
        <BanknotesIcon class="mr-5 w-10 h-10 fill-lk-green md:w-12 md:h-12" />
        <div class="flex flex-col w-full">
          <div class="flex justify-between w-full">
            <p class="text-lg border-b border-transparent md:text-xl">
              Pago
              <span class="text-lk-green">#{{ shopPayment.id }}</span>
            </p>
            <p class="text-gray-700">
              {{ useTimeToDate(shopPayment.createdAt) }}
            </p>
          </div>
          <div class="flex items-center w-full">
            <div class="flex flex-col text-left">
              <p class="font-light">
                Monto {{ shopPayment.kind === 'return' ? 'devolución' : 'pagado' }}:
                {{ useFormatCurrency(shopPayment.amountCents) }}
              </p>
              <p class="text-sm font-light text-left">
                Categoría: {{ $t(`shopPayment.kind.${camelCase(shopPayment.kind)}`) }}
              </p>
            </div>
            <ChevronDownIcon
              class="shrink-0 ml-auto w-5 h-5"
              :class="{ 'rotate-180 transition transform duration-150': showOrderPayments }"
            />
          </div>
        </div>
      </div>
      <div
        v-if="showOrderPayments"
        class="flex flex-col pt-1 w-full border-t md:pt-2"
      >
        <lokal-loading
          v-if="isLoading"
          :loading="isLoading"
          size="small"
        />
        <p
          v-for="orderPayment in orderPayments"
          v-else
          :key="orderPayment.id"
          class="mb-1 text-sm font-light text-left"
        >
          <template v-if="!!orderPayment.orderId">
            <a
              v-if="!!orderPayment.orderId"
              :href="`/orders/${orderPayment.orderId}`"
              target="_blank"
              class="hover:underline"
              @click.stop
            >
              Abono a la Orden #{{ orderPayment.orderId }}
              <span v-if="!!orderPayment.invoiceNumber">
                (factura #{{ orderPayment.invoiceNumber }})
              </span>
            </a>
            por {{ useFormatCurrency(orderPayment.amountCents) }}.
          </template>
          <span v-else>
            Saldo a favor de {{ useFormatCurrency(orderPayment.amountCents) }} por cambio o falta de productos
          </span>
        </p>
      </div>
    </div>
  </button>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { camelCase } from 'lodash';
import { BanknotesIcon, ChevronDownIcon } from '@heroicons/vue/24/solid';
import { useQuery } from 'vue-query';
import shopPaymentsApi from 'api/shop-payments';
import useTimeToDate from './use/time-to-date';
import useFormatCurrency from './use/format-currency';

const props = defineProps<{
  shopPayment: ShopPayment
}>();

const showOrderPayments = ref(false);
const orderPaymentsQuery = useQuery(
  ['shopPayments', props.shopPayment.id], () => shopPaymentsApi.orderPayments(props.shopPayment.id),
  { enabled: computed(() => showOrderPayments.value) },
);
const orderPayments = computed(() => orderPaymentsQuery.data?.value?.data.orderPayments as OrderPayment[]);
const isLoading = computed(() => orderPaymentsQuery.isLoading.value);
</script>
