<template>
  <transition
    enter-active-class="transition-transform duration-300"
    enter-from-class="-translate-x-full md:translate-x-full"
    enter-to-class="translate-x-0"
    leave-active-class="transition-transform duration-300"
    leave-from-class="translate-x-0"
    leave-to-class="-translate-x-full md:translate-x-full"
  >
    <aside
      id="maker-filter-sidebar"
    >
      <div class="h-full px-3 sm:px-4 sm:py-6 md:px-5 md:py-10 ">
        <div
          v-if="crossEnabled"
          class="flex w-full"
        >
          <button
            class="ml-auto mt-2"
            @click="menuStore.showProductFilterSidebar = !menuStore.showProductFilterSidebar"
          >
            <XMarkIcon class="size-5" />
          </button>
        </div>
        <div class="mb-2 flex items-center text-lg md:text-2xl">
          <FunnelIcon
            class="mr-2 size-5 fill-lk-green text-lk-green md:size-6"
          />
          <p>Filtros</p>
        </div>
        <div class="flex flex-wrap items-center">
          <button
            v-for="record in selectedCategories"
            :key="record.id"
            class="mb-1 mr-1 flex items-center rounded-md bg-lk-green p-1 text-sm text-white"
            @click="removeSelectedCategory(record)"
          >
            {{ record.name }}
            <XCircleIcon class="size-4 cursor-pointer fill-white text-lk-green" />
          </button>
          <button
            v-for="record in selectedLabels"
            :key="record.id"
            class="mb-1 mr-1 flex items-center rounded-md bg-lk-green p-1 text-sm text-white"
            @click="removeSelectedLabel(record)"
          >
            {{ record.name }}
            <XCircleIcon class="size-4 cursor-pointer fill-white text-lk-green" />
          </button>
        </div>
        <div class="mb-3 flex flex-col" />
        <div class="w-full border-b" />
        <p class="my-2 text-base md:text-xl">
          Rápidos
        </p>
        <button
          v-if="sessionStore.user?.shopId"
          class="mb-2 flex w-full flex-col rounded-md border bg-white p-3 text-left md:bg-gray-50"
          :class="{ 'border-lk-green': showOnlyProductsWithFreeShippingToCurrentAddress }"
          type="button"
          @click="showOnlyProductsWithFreeShippingToCurrentAddress = !showOnlyProductsWithFreeShippingToCurrentAddress"
        >
          <div class="flex w-full items-center">
            <TruckIcon class="size-5 shrink-0 md:size-6" />
            <lokal-switch
              v-model="showOnlyProductsWithFreeShippingToCurrentAddress"
              class="ml-auto"
            />
          </div>
          <p>Despacho gratis a tu dirección</p>
        </button>
        <button
          class="mb-2 flex w-full flex-col rounded-md border bg-white p-3 text-left md:bg-gray-50"
          :class="{ 'border-lk-green': showOnlyMakersWithSubsidizedShipping }"
          type="button"
          @click="showOnlyMakersWithSubsidizedShipping = !showOnlyMakersWithSubsidizedShipping"
        >
          <div class="flex w-full items-center">
            <TruckIcon class="size-5 shrink-0 md:size-6" />
            <lokal-switch
              v-model="showOnlyMakersWithSubsidizedShipping"
              class="ml-auto"
            />
          </div>
          <p>Despacho con descuento a tu dirección</p>
        </button>
        <button
          class="mb-2 flex w-full flex-col rounded-md border bg-white p-3 text-left md:bg-gray-50"
          :class="{ 'border-lk-green': discountProducts }"
          type="button"
          @click="discountProducts = !discountProducts"
        >
          <div class="flex w-full items-center">
            <TagIcon class="size-5 shrink-0 md:size-6" />
            <lokal-switch
              v-model="discountProducts"
              class="ml-auto"
            />
          </div>
          <p>Descuentos</p>
        </button>
        <button
          class="mb-2 flex items-center"
          @click="onylRecommendedProducts = !onylRecommendedProducts"
        >
          <div
            class="mr-2 size-4 rounded-md border"
            :class="{ 'border-0 bg-lk-green transition duration-300 ease-in-out': onylRecommendedProducts }"
          />
          <p class="text-left">
            Recomendados
          </p>
        </button>
        <div class="w-full border-b" />
        <template v-if="withMakerFilter">
          <p class="my-2 text-base md:text-xl">
            Marcas
          </p>
          <input
            v-model="makerSearchText"
            class="mb-2 w-full rounded-md border p-1 text-sm focus:border-lk-green focus:outline-none"
            placeholder="Buscar marca"
          >
          <div
            class="mb-2 flex h-72 w-full flex-col overflow-auto"
          >
            <filter-options-placeholder
              v-if="makersIsLoading"
              :is-loading="makersIsLoading"
            />
            <button
              v-for="maker in makers"
              v-else
              :key="`${maker.slug}`"
              class="flex items-center"
              @click="updateSelectedMakers(maker)"
            >
              <div
                class="mr-2 size-4 rounded-md border"
                :class="{ 'border-0 bg-lk-green': selectedMakerIds.includes(maker?.slug) }"
              />
              <p class="self-start text-left hover:text-lk-green">
                {{ maker?.name }}
              </p>
            </button>
            <button
              v-if="makersInfiniteQuery.hasNextPage"
              class="w-full text-left"
              type="button"
              @click="makersInfiniteQuery.fetchNextPage.value()"
            >
              Cargar más marcas
            </button>
          </div>
          <div class="w-full border-b" />
        </template>
        <template v-if="makerSlug">
          <p class="my-2 text-base md:text-xl">
            Colecciones
          </p>
          <input
            v-model="categorySearchText"
            class="mb-2 w-full rounded-md border p-1 text-sm focus:border-lk-green focus:outline-none"
            placeholder="Buscar colleción"
          >
          <lokal-loading
            v-if="isCollectionsLoading"
            :loading="isCollectionsLoading"
            size="small"
          />
          <div
            v-else
            class="mb-2 flex w-full flex-col"
          >
            <button
              v-for="collection in filteredCollections"
              :key="collection.id"
              class="flex items-center"
              @click="updateSelectedCollections(collection)"
            >
              <div
                class="mr-2 size-4 rounded-md border"
                :class="{ 'border-0 bg-lk-green transition duration-300 ease-in-out':
                  selectedCollectionIds.includes(collection.id) }"
              />
              <p class="self-start text-left hover:text-lk-green">
                {{ collection.name }}
              </p>
            </button>
          </div>
        </template>
        <p class="my-2 text-base md:text-xl">
          Categorías
        </p>
        <input
          v-model="categorySearchText"
          class="mb-2 w-full rounded-md border p-1 text-sm focus:border-lk-green focus:outline-none"
          placeholder="Buscar categoría"
        >
        <div
          class="mb-2 flex w-full flex-col"
        >
          <filter-options-placeholder
            v-if="categoryQuery.isLoading.value"
            :is-loading="categoryQuery.isLoading.value"
          />
          <button
            v-for="category in filteredCategories"
            v-else
            :key="category.id"
            class="flex items-center"
            @click="updateSelectedCategories(category)"
          >
            <div
              class="mr-2 size-4 rounded-md border"
              :class="{
                'border-0 bg-lk-green transition duration-300 ease-in-out': selectedCategoryIds.includes(category.id)
              }"
            />
            <p class="self-start text-left hover:text-lk-green">
              {{ category.name }}
            </p>
          </button>
        </div>
        <div class="w-full border-b" />
        <p class="my-2 text-base md:text-xl">
          Etiquetas
        </p>
        <input
          v-model="labelSearchText"
          class="mb-2 w-full rounded-md border p-1 text-sm focus:border-lk-green focus:outline-none"
          placeholder="Buscar etiquetas"
        >
        <div
          class="mb-2 h-72 overflow-auto"
        >
          <filter-options-placeholder
            v-if="labelsQuery.isLoading.value"
            :is-loading="labelsQuery.isLoading.value"
            :placeholder-count="8"
          />
          <button
            v-for="label in filteredLabels"
            v-else
            :key="label.id"
            class="flex items-center"
            @click="updateSelectedLabels(label)"
          >
            <div
              class="mr-2 size-4 rounded-md border"
              :class="{
                'border-0 bg-lk-green transition duration-300 ease-in-out': selectedLabelIds.includes(label.id)
              }"
            />
            <div class="self-start hover:text-lk-green">
              {{ label.name }}
            </div>
          </button>
        </div>
        <div class="w-full border-b" />
      </div>
    </aside>
  </transition>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useInfiniteQuery } from 'vue-query';
import { sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import makersApi from 'api/makers';
import { FunnelIcon, XMarkIcon, XCircleIcon, TruckIcon, TagIcon } from '@heroicons/vue/24/outline';
import useGeneralMenuStore from 'stores/general-menu-store';
import useProductFilterStore from 'stores/product-filter-store';
import useSessionStore from 'stores/user-store';
import useUrlMethods from './use/url-methods';
import useCategoriesQuery from './queries/categories-query';
import LokalSwitch from './shared/lokal-switch.vue';
import useLabelsQuery from './queries/labels-query';
import useCollectionsQuery from './queries/collections-query';
import useCleanAccents from './use/clean-accents';
import filterOptionsPlaceholder from './shared/filter-options-placeholder.vue';

interface ProductListAsideFiltersProps {
  withMakerFilter?: boolean,
  makerSlug?: string,
  crossEnabled?: boolean,
}

const props = withDefaults(defineProps<ProductListAsideFiltersProps>(), {
  makerSlug: '',
  withMakerFilter: true,
  çrossEnabled: false,
});

const sessionStore = useSessionStore();
const menuStore = useGeneralMenuStore();

const productFilterStore = useProductFilterStore();
const { selectedMakers, selectedCategories, selectedMakerIds, selectedCategoryIds,
  onylRecommendedProducts, showOnlyProductsWithFreeShippingToCurrentAddress, selectedLabels, selectedLabelIds,
  selectedCollections, selectedCollectionIds, discountProducts, showOnlyMakersWithSubsidizedShipping,
} = storeToRefs(productFilterStore);

const { getQueryParam } = useUrlMethods();

const makerSearchText = ref('');
const makerQueryParams = computed(() => {
  let base = 'q[s][]=name+asc';
  if (makerSearchText.value) base += `&q[name_cont]=${encodeURIComponent(useCleanAccents(makerSearchText.value))}`;

  return base;
});

function fetchMakers({ pageParam = 1 }) {
  return makersApi.plaineActive(pageParam, makerQueryParams.value);
}
const makersInfiniteQuery = useInfiniteQuery(['infinite-makers', makerQueryParams], fetchMakers, {
  getNextPageParam: (lastPage) => parseInt(lastPage.data.headers['x-page'], 10) + 1,
});
const makers = computed(() =>
  makersInfiniteQuery.data.value?.pages?.reduce((acc, page) => [...acc, ...page?.data?.makers], [] as Maker[]));
const makersIsLoading = computed(() => makersInfiniteQuery.isLoading.value);

const colletionsQueryParams = computed(() => `q[maker_slug_eq]=${props.makerSlug}`);
const { collections, isCollectionsLoading, collectionsQuery } = useCollectionsQuery(
  colletionsQueryParams, computed(() => !!props.makerSlug),
);

const categoriesQueryParams = computed(() => {
  if (props.makerSlug) {
    return `q[makers_slug_eq]=${props.makerSlug}`;
  } else if (selectedMakers.value?.length) {
    return selectedMakers.value?.map((maker) => `q[makers_id_in][]=${maker.id}`).join('&') || '';
  }

  return '';
});
const { categories, categoryQuery } = useCategoriesQuery(categoriesQueryParams);
const { labels, labelsQuery } = useLabelsQuery(categoriesQueryParams, computed(() => true));

const collectionSearchText = ref('');
const filteredCollections = computed(() => {
  if (collectionSearchText.value.length > 0) {
    return sortBy(collections.value?.filter((collection) =>
      collection.name.toLowerCase().includes(collectionSearchText.value.toLowerCase()))
    , (collection) => collection.name);
  }

  return sortBy(collections.value, (collection) => collection.name);
});

function updateSelectedCollections(collection: Collection) {
  if (selectedCollectionIds.value.includes(collection.id)) {
    selectedCollections.value = selectedCollections.value.filter((record) => record.name !== collection.name);
  } else {
    selectedCollections.value = [collection];
  }
}

function updateSelectedMakers(maker: Maker) {
  if (selectedMakerIds.value.includes(maker.slug)) {
    selectedMakers.value = selectedMakers.value.filter((record) => record.slug !== maker.slug);
  } else {
    selectedMakers.value.push(maker);
  }
}

const categorySearchText = ref('');
const filteredCategories = computed(() => {
  if (categorySearchText.value.length > 0) {
    return sortBy(categories.value?.filter((category) =>
      category.name.toLowerCase().includes(categorySearchText.value.toLowerCase()))
    , (category) => category.priority);
  }

  return sortBy(categories.value, (category) => category.priority);
});

function updateSelectedCategories(category: Category) {
  if (selectedCategoryIds.value.includes(category.id)) {
    selectedCategories.value = selectedCategories.value.filter((record) => record.id !== category.id);
  } else {
    selectedCategories.value.push(category);
  }
}

const labelSearchText = ref('');
const filteredLabels = computed(() => {
  if (labelSearchText.value.length > 0) {
    return sortBy(labels.value?.filter((label) =>
      label.name.toLowerCase().includes(labelSearchText.value.toLowerCase()))
    , (label) => label.name);
  }

  return sortBy(labels.value, (label) => label.name);
});

function updateSelectedLabels(label: Label) {
  if (selectedLabelIds.value.includes(label.id)) {
    selectedLabels.value = selectedLabels.value.filter((record) => record.id !== label.id);
  } else {
    selectedLabels.value.push(label);
  }
}

function setSelectedMakersFromQueryParam() {
  const makerIds = getQueryParam('maker_ids')?.split(',');
  if (makers.value?.length && !!makerIds && makerIds.length > 0) {
    selectedMakers.value = makers.value.filter((maker) => makerIds.includes(maker.slug));
  }
}

function setSelectedCollectionsFromQueryParam() {
  const collectionIds = getQueryParam('collection_ids')?.split(',');
  if (collections.value?.length && !!collectionIds && collectionIds.length > 0) {
    selectedCollections.value = collections.value.filter((collection) => collectionIds.includes(`${collection.id}`));
  }
}

function setSelectedCategoriesFromQueryParam() {
  const categoryIds = getQueryParam('category_ids');
  if (categories.value?.length && !!categoryIds && categoryIds.length > 0) {
    selectedCategories.value = categories.value?.filter((category) => categoryIds.includes(`${category.id}`));
  }
}

function setSelectedLabelsFromQueryParam() {
  const labelIds = getQueryParam('label_ids');
  if (labels.value?.length && !!labelIds && labelIds.length > 0) {
    selectedLabels.value = labels.value?.filter((subCategory) => labelIds.includes(`${subCategory.id}`));
  }
}

function setShowOnlyMakersWithSubsidizedShippingFromQueryParam() {
  const showOnlyMakersWithSubsidizedShippingQueryParam = getQueryParam('subsidized_shippings');
  if (showOnlyMakersWithSubsidizedShippingQueryParam) {
    showOnlyMakersWithSubsidizedShipping.value = true;
  }
}

function setShowOnlyProductsWithFreeShippingToCurrentAddressFromQueryParam() {
  const showOnlyProductsWithFreeShippingToCurrentAddressQueryParam = getQueryParam('with_free_shippings');
  if (showOnlyProductsWithFreeShippingToCurrentAddressQueryParam) {
    showOnlyProductsWithFreeShippingToCurrentAddress.value = true;
  }
}

onMounted(async () => {
  await Promise.all([
    makersInfiniteQuery.suspense(), collectionsQuery.suspense(), categoryQuery.suspense(), labelsQuery.suspense(),
  ]);
  setSelectedMakersFromQueryParam();
  setSelectedCollectionsFromQueryParam();
  setSelectedCategoriesFromQueryParam();
  setSelectedLabelsFromQueryParam();
  setShowOnlyMakersWithSubsidizedShippingFromQueryParam();
  setShowOnlyProductsWithFreeShippingToCurrentAddressFromQueryParam();
});

function removeSelectedCategory(category: Category) {
  selectedCategories.value = selectedCategories.value.filter((record) => record.id !== category.id);
}

function removeSelectedLabel(label: Label) {
  selectedLabels.value = selectedLabels.value.filter((record) => record.id !== label.id);
}
</script>
