<template>
  <div class="flex flex-col mb-2 w-full">
    <drag-drop
      v-if="maxNumberOfFiles && Object.keys(files).length <= (maxNumberOfFiles - 1)"
      :class="{ 'hide-arrow': hideArrow }"
      :uppy="uppy"
      :props="{
        theme: 'light',
        height: dragDropHeight,
        note: `Máximo ${maxNumberOfFiles} archivos con extensión [${fileType}]`,
        locale: {
          strings: {
            dropHereOr: 'Arrastra aquí o %{browse}',
            browse: 'Navega',
          },
        },
      }"
    />
    <div
      v-for="file in files"
      :key="file.id"
      class="flex items-center mt-2 space-x-2 text-gray-700"
    >
      <file-uploader-new-file
        :file="file"
        :uppy="uppy"
      />
    </div>
    <p
      v-if="maxNumberOfFiles && Object.keys(files).length >= maxNumberOfFiles"
      class="my-1 text-xs font-light text-gray-500 md:text-sm"
    >
      Máximo {{ maxNumberOfFiles }} archivos. Para subir más archivos, elimina algunos.
    </p>
  </div>
</template>

<script lang="ts" setup>
import Uppy, { type UppyFile } from '@uppy/core';
import { DragDrop } from '@uppy/vue';
import XHRUpload from '@uppy/xhr-upload';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { ref, computed, onBeforeUnmount } from 'vue';
import convertKeys from 'utils/convert-keys';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import fileUploaderNewFile from './file-uploader-new-file.vue';

interface FileUploaderProps {
  maxNumberOfFiles?: number | null;
  fileType?: string[] | null;
  dragDropHeight?: string;
  hideArrow?: boolean;
}

const props = withDefaults(defineProps<FileUploaderProps>(), {
  maxNumberOfFiles: null,
  fileType: null,
  dragDropHeight: '200px',
  hideArrow: false,
});

const emit = defineEmits<{(e: 'update-files', value: { priority: number, imageData: ImageData }[]): void,
  (e: 'update-images'): void, (e: 'update-file-paths', value: { [key: string]: string}): void,
  (e: 'update:files-data', value: { [key: string]: ImageData | DocumentData}): void
}>();

const uppy = new Uppy({
  restrictions: {
    maxFileSize: 5000000, maxNumberOfFiles: props.maxNumberOfFiles, allowedFileTypes: props.fileType,
  },
  autoProceed: true,
})
  .use(ThumbnailGenerator, { thumbnailWidth: 200 })
  .use(XHRUpload, { endpoint: '/upload' });

const files = ref({} as { [key: string]: UppyFile});
const filesPriority = ref({} as { [key: string]: number});
const filesPath = ref({} as { [key: string]: string});
const filesImageData = ref({} as { [key: string]: ImageData});
const filesData = ref({} as {[key: string]: ImageData | DocumentData});
const fileDatas = computed(() => Object.keys(files.value).map((key) => ({
  priority: filesPriority.value[key],
  imageData: filesImageData.value[key],
  extension: files.value[key].extension,
})));

onBeforeUnmount(() => {
  uppy.close({ reason: 'unmount' });
});

uppy.on('file-added', (file: UppyFile) => {
  files.value[file.id] = file;
});

uppy.on('thumbnail:generated', (file: UppyFile) => {
  files.value[file.id] = file;
});

uppy.on('file-removed', (file: UppyFile) => {
  delete files.value[file.id];
});

uppy.on('upload-success', (file, response) => {
  const uploadedFileData = response.body.data;
  const uppyFile = file as UppyFile;
  filesImageData.value[uppyFile.id] = uploadedFileData;
  filesData.value[uppyFile.id] = uploadedFileData;
  filesData.value = convertKeys(filesData.value, 'camelize') as { [key: string]: ImageData | DocumentData};
  if (response?.uploadURL) filesPath.value[uppyFile.id] = response.uploadURL;
  emit('update-files', fileDatas.value);
  emit('update:files-data', filesData.value);
  emit('update-file-paths', filesPath.value);
});
</script>
<style lang="scss">
/* Add this at the bottom of your component */
.hide-arrow .uppy-DragDrop-arrow {
  display: none !important;
}
.uppy-DragDrop-inner {
  padding: 16px
}
.uppy-DragDrop-note {
  @apply text-xs md:text-sm;
}
</style>
