<template>
  <div class="flex flex-col w-full">
    <lokal-loading
      v-if="billingInformationIsLoading"
      :loading="billingInformationIsLoading"
    />
    <template v-else>
      <maker-billing-information-general-form
        class="mb-3"
        :maker="maker"
        :billing-information="billingInformation"
        @reload:billing-information="async () => await billingInformationQuery.refetch.value()"
      />
      <maker-billing-information-bank-form
        :maker="maker"
        :billing-information="billingInformation"
        @reload:billing-information="async () => await billingInformationQuery.refetch.value()"
      />
    </template>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import billingInformationsApi from 'api/billing-informations';
import makerBillingInformationBankForm from './maker-billing-information-bank-form.vue';
import makerBillingInformationGeneralForm from './maker-billing-information-general-form.vue';

const props = defineProps<{
  maker: Maker;
}>();

const billingInformationQuery = useQuery(
  ['billingInformation', props.maker.id],
  () => billingInformationsApi.get(props.maker.id),
);
const billingInformation = computed(() =>
billingInformationQuery.data.value?.data?.billingInformation as BillingInformation);
const billingInformationIsLoading = computed(() => billingInformationQuery.isLoading.value);
</script>
