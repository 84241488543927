<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center px-2">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-lg max-h-[500px] bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-6 h-6 fill-gray-700" />
        </button>
        <DialogTitle
          class="mb-2 font-serif text-xl text-center text-gray-700 md:text-3xl"
        >
          Reporte de incidencia
        </DialogTitle>
        <p class="mb-5 text-sm text-center text-gray-700 md:text-base">
          Por favor, describe el problema que estás experimentando lo más detalladamente posible.
        </p>
        <DialogDescription
          class="mb-5 text-sm text-gray-700 md:text-base"
        >
          <lokal-loading
            v-if="isLoading"
            :loading="isLoading"
            class="self-center"
          />
          <form
            v-else-if="subOrders?.length && subOrders.length > 0"
            @submit="onSubmit"
          >
            <p class="mb-1 text-sm font-light md:text-base">
              Selecciona el pedido afectado
            </p>
            <div class="flex flex-col mb-2">
              <lokal-generic-combobox
                v-if="subOrders?.length && subOrders.length > 0"
                v-model="selectedSubOrder"
                :options="subOrders"
                :get-option-key="(subOrder: SubOrder) => subOrder.id"
                :get-option-label="(subOrder: SubOrder) => subOrder?.orderId ? getSubOrderLabel(subOrder) : ''"
                compare-by="id"
                options-label="Pedidos"
                :multiple="false"
                class="font-light"
              />
              <Field
                v-model="selectedSubOrderId"
                name="subOrderId"
                label="Pedido"
                class="w-full"
                hidden
                :rules="{ required: true }"
              />
              <ErrorMessage
                name="subOrderId"
                class="mt-1 text-xs font-light text-red-500 md:text-sm"
              />
              <p class="text-xs font-light text-gray-500 md:text-sm">
                Si no encuentras tu pedido puedes escribirnos al
                <a
                  :href="`https://wa.me/${contactNumber}`"
                  class="hover:underline"
                  target="_blank"
                >
                  +{{ contactNumber }}
                </a>.
              </p>
            </div>
            <p class="mb-1 text-sm font-light md:text-base">
              Descripción
            </p>
            <div class="flex flex-col mb-3 md:mb-4">
              <tip-tap-editor
                v-model="description"
                class="w-full bg-white rounded-md"
                :placeholder="tipTapPlaceholder"
                :style="'min-height: 6rem'"
              />
              <Field
                v-model="description"
                name="description"
                label="Descripción"
                class="hidden w-full"
                :rules="{ required: true }"
              />
              <ErrorMessage
                name="description"
                class="mt-1 text-xs font-light text-red-500 md:text-sm"
              />
            </div>
            <p class="mb-1 text-sm font-light md:text-base">
              Adjunta imágenes o documentos
            </p>
            <file-uploader
              max-number-of-files="6"
              :file-type="['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']"
              class="p-2 mb-3 bg-white rounded-md"
              drag-drop-height="100px"
              :hide-arrow="true"
              @update-file-paths="(value: Record<string, string>) => filesPath = value"
              @update:files-data="(newFilesData: Record<string, ImageData | DocumentData>) => filesData = newFilesData"
            />
            <lokal-loading
              v-if="isLoading"
              :loading="isLoading"
              class="self-center"
            />
            <button
              v-else
              type="submit"
              class="p-1.5 w-full text-sm font-normal text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
            >
              Crear reporte de incidencia
            </button>
          </form>
          <div v-else>
            <p class="text-sm font-light md:text-base">
              No se encontraron pedidos habilitados para reportar incidencias. Si crees que esto
              es un error, por favor contáctanos al
              <a
                :href="`https://wa.me/${contactNumber}`"
                class="text-blue-500 hover:underline"
                target="_blank"
              >
                +{{ contactNumber }}
              </a>.
            </p>
          </div>
        </DialogDescription>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import { ref, computed } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { useMutation, useQuery } from 'vue-query';
import useMessageStore from 'stores/message-store';
import useSessionStore from 'stores/user-store';
import incidenceReportsApi from 'api/incidence-reports';
import subOrdersApi from 'api/sub-orders';
import imagesApi from 'api/images';
import documentsApi from 'api/documents';
import useDateTools from './use/date-tools';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import fileUploader from './shared/file-uploader.vue';

interface IncidenceNewDialogProps {
  modelValue: boolean
  shopId: number
}
const props = withDefaults(defineProps<IncidenceNewDialogProps>(), {
  modelValue: false,
});

const sessionStore = useSessionStore();
const { timeToDate } = useDateTools();
const messageStore = useMessageStore();
const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void, (e: 'reload:incidence-reports'): void }>();

const contactNumber = computed(() => process.env.CONTACT_NUMBER);

const filesData = ref({} as { [key: string]: ImageData | DocumentData});
const filesPath = ref({} as { [key: string]: string});
const description = ref('');
const documentKeys = computed(() => Object.keys(filesData.value).filter(
  (fileKey) => filesData.value[fileKey].metadata.mimeType.includes('pdf'),
));
const imageKeys = computed(() => Object.keys(filesData.value).filter(
  (fileKey) => !filesData.value[fileKey].metadata.mimeType.includes('pdf'),
));
const currentPage = ref(1);
const oneWeekAgo = computed(() => {
  const date = new Date();
  const maxDaysBefore = 7;
  date.setDate(date.getDate() - maxDaysBefore);

  return date;
});
const oneMonthAgo = computed(() => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  return date;
});
const queryParams = computed(() => {
  const deliveryInProgressStatus = 3;
  const deliveredStatus = 4;
  const baseQuery = [
    `q[groupings][0][delivered_at_gteq]=${timeToDate(oneWeekAgo.value)}`,
    `q[groupings][1][status_in][]=${deliveryInProgressStatus}`,
    `q[groupings][1][status_in][]=${deliveredStatus}`,
    `q[groupings][1][pending_at_gteq]=${timeToDate(oneMonthAgo.value)}`,
    'q[m]=or',
    'q[s]=pending_at desc',
  ];

  return baseQuery.join('&');
});
const subOrdersQuery = useQuery(
  ['subOrders', props.shopId, currentPage, queryParams],
  () => subOrdersApi.shopIndex(props.shopId, currentPage.value, queryParams.value),
  {
    enabled: computed(() => !!props.shopId),
  },
);
const subOrders = computed(() => subOrdersQuery.data?.value?.data?.subOrders as SubOrder[]);
const selectedSubOrder = ref({} as SubOrder);
const selectedSubOrderId = computed(() => selectedSubOrder.value?.id);

function createDocuments(incidenceReport: IncidenceReport) {
  const documents = documentKeys.value.map((fileKey) => ({
    documentableId: incidenceReport.id,
    documentableType: 'IncidenceReport',
    document: filesData.value[fileKey],
  })) as Partial<Document>[];

  return documentsApi.bulkCreate(documents);
}

function createImages(incidenceReport: IncidenceReport) {
  const images = imageKeys.value.map((fileKey) => ({
    imagableId: incidenceReport.id,
    imagableType: 'IncidenceReport',
    image: filesData.value[fileKey],
  })) as Partial<Image>[];

  return imagesApi.bulkCreate(images);
}

const incidenceReportCreateMutation = useMutation(
  () => incidenceReportsApi.create({
    description: description.value,
    userId: sessionStore.user.id,
    subOrderId: selectedSubOrder.value.id,
  }),
  {
    onSuccess: async (data) => {
      const createdIncidenceReport = data?.data?.incidenceReport;
      if (Object.keys(filesData.value).length > 0) {
        if (imageKeys.value.length > 0) await createImages(createdIncidenceReport);
        if (documentKeys.value.length > 0) await createDocuments(createdIncidenceReport);
      }
      messageStore.showMessage('Incidencia creada correctamente', 'success');
      emit('update:modelValue', false);
      emit('reload:incidence-reports');
    },
  },
);

const isLoading = computed(() =>
  incidenceReportCreateMutation.isLoading.value ||
  subOrdersQuery.isLoading.value,
);

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(async () => {
  incidenceReportCreateMutation.mutate();
});

function getSubOrderLabel(subOrder: SubOrder) {
  let baseLabel = '';
  if (!!subOrder?.id) baseLabel += `${subOrder.makerName} - Orden #${subOrder.orderId}`;

  return baseLabel;
}

const tipTapPlaceholder = computed(() => {
  let base = 'Decribe el problema que tienes...\nEj. Los siguientes productos llegaron dañados:\n';
  base += '  - Kombucha 350cc Berries (3 un)\n - Bulto externo llegó dañado/adulterado';

  return base;
});
</script>
