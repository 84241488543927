<template>
  <div class="flex flex-col p-5 w-full">
    <div class="flex flex-col">
      <p>
        Selecciona una marca:
      </p>
      <lokal-generic-combobox
        v-model="selectedMaker"
        class="w-full"
        :get-option-key="(option: Maker) => option.id"
        :get-option-label="(option: Maker) => option.name"
        :options="sortedMakers"
        :options-label="`Selecciona una marca`"
        :multiple="false"
      />
      <p>
        Selecciona una comuna de destino:
      </p>
      <lokal-loading
        v-if="communesQuery.isLoading.value"
        :loading="communesQuery.isLoading.value"
        size="small"
      />
      <lokal-generic-combobox
        v-else
        v-model="selectedCommune"
        class="w-full"
        :get-option-key="(option: Commune) => option.id"
        :get-option-label="(option: Commune) => option.name"
        :options="communes"
        :options-label="`Selecciona una comuna`"
        :multiple="false"
      />
    </div>
    <shipping-quotation
      v-if="selectedMaker?.id && selectedCommune?.id"
      :maker="selectedMaker"
      :commune="selectedCommune"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { orderBy } from 'lodash';
import useCommunesQuery from './queries/communes-query';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import shippingQuotation from './shipping-quotation.vue';

const props = defineProps<{
  makers: Maker[],
}>();

const { communes, communesQuery } = useCommunesQuery();
const selectedMaker = ref({} as Maker);
const sortedMakers = computed(() => orderBy(props.makers, 'name'));
const selectedCommune = ref({} as Commune);
</script>
