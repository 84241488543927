<template>
  <form
    class="flex flex-col w-full"
    @submit="onSubmit"
  >
    <Field
      class="p-2 mb-0 text-xs rounded-md border border-lk-light-gray focus:border-lk-green focus:outline-none md:text-sm"
      :model-value="modelValue"
      :name="name"
      :rules="rules"
      @update:model-value="updateModelValue"
      @change="debounceIsTyping"
    />
    <ErrorMessage
      :name="name"
      class="mb-1 text-xs text-red-500 md:text-sm"
    />
  </form>
</template>
<script setup lang="ts">
import { Field, useForm, ErrorMessage } from 'vee-validate';
import { rutFormat } from 'rut-helpers';
import { debounce } from 'lodash';
import { onMounted } from 'vue';

const DEBOUNCE_TIME = 500;

interface AutoFormInputProps {
  modelValue: string | number
  name: string
  label: string
  rules: Record<string, string | boolean | number | string[]>
}

const props = defineProps<AutoFormInputProps>();

const emit = defineEmits<{(e: 'update:modelValue', value: string | number): void,
  (e: 'submit'): void,
}>();

const { validate } = useForm();

function updateModelValue(value: string | number) {
  if (props.rules.rut) {
    const formattedValue = rutFormat(value);
    emit('update:modelValue', formattedValue);
  } else {
    emit('update:modelValue', value);
  }
}

async function onUpdateModelValue() {
  const validation = await validate();
  if (validation.valid) {
    emit('submit');
  }
}

const debounceIsTyping = debounce(() => {
  onUpdateModelValue();
}, DEBOUNCE_TIME);

onMounted(() => {
  if (!!props.rules.rut) {
    const formattedValue = rutFormat(props.modelValue);
    emit('update:modelValue', formattedValue);
  }
});
</script>
