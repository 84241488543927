import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(makerId: number, pageNumber: number, searchParams?: string) {
    const queryParams = `page[number]=${pageNumber}&page[size]=24&${searchParams}`;

    return api({
      method: 'get',
      url: `/api/internal/referred_contacts?maker_id=${makerId}&${queryParams}`,
      headers,
    });
  },
  indexCount(makerId: number, searchParams?: string) {
    return api({
      method: 'get',
      url: `/api/internal/referred_contacts/index_count?maker_id=${makerId}&${searchParams}`,
      headers,
    });
  },
  metrics(makerId: number, searchParams?: string) {
    return api({
      method: 'get',
      url: `/api/internal/referred_contacts/metrics?maker_id=${makerId}&${searchParams}`,
      headers,
    });
  },
};
