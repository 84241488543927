<template>
  <div class="flex flex-col w-full">
    <div class="flex justify-between items-center w-full text-sm text-gray-700 rounded-md border shadow-sm md:text-base">
      <p class="flex-1 p-2 break-all rounded-sm border-r-0 md:py-3">
        {{ text }}
      </p>
      <button
        class="border-l"
        @click="copyToClipBoard"
      >
        <ClipboardDocumentCheckIcon
          v-if="copiedText"
          class="m-2 w-5 text-lk-green md:m-3 md:w-6"
        />
        <ClipboardDocumentIcon
          v-else
          class="m-2 w-5 md: md:m-3 md:w-6"
        />
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
import useMessageStore from 'stores/message-store';

interface CopyTextProps {
  text: string
  successMessage?: string
}

const props = withDefaults(defineProps<CopyTextProps>(), {
  successMessage: 'Texto copiado correctamente',
});

const messageStore = useMessageStore();

const copiedText = ref(false);
function copyToClipBoard() {
  navigator.clipboard.writeText(props.text).then(() => {
    copiedText.value = true;
    messageStore.showMessage(props.successMessage, 'success', 'short');
  });
}
</script>
