<template>
  <div class="flex flex-col w-full">
    <p class="mb-1 text-base font-semibold md:text-lg">
      Información de facturación
    </p>
    <div class="mb-2 w-full border-b border-gray-700" />
    <lokal-loading v-if="generalLoading" />
    <form
      v-else
      class="flex flex-col w-full"
      @submit="onSubmit"
    >
      <p class="text-sm md:text-base">
        Rut de empresa
      </p>
      <auto-form-input
        v-model="businessRut"
        name="businessRutAuto"
        :rules="{ required: true, rut: true }"
        @submit="updateBillingInformation({ businessRut })"
      />
      <p class="text-sm md:text-base">
        Razón social
      </p>
      <auto-form-input
        v-model="socialReason"
        name="socialReasonAuto"
        :rules="{ required: true }"
        @submit="updateBillingInformation({ socialReason })"
      />
      <p class="text-sm md:text-base">
        Giro comercial
      </p>
      <auto-form-input
        v-model="commercialBusiness"
        name="commercialBusinessAuto"
        :rules="{ required: true }"
        @submit="updateBillingInformation({ commercialBusiness })"
      />
      <p class="text-sm md:text-base">
        Dirección de facturación
      </p>
      <lokal-generic-combobox
        v-model="address"
        :options-label="`Seleccione una dirección`"
        class="font-light"
        :class="{ 'border-red-500 border rounded-md': errors.address }"
        :multiple="false"
        compare-by="id"
        :local-search="false"
        :options="addresses"
        :get-option-key="(address: Address) => address.id"
        :get-option-label="(address: Address) => address?.id ? `${address.address}, ${address.communeName}` : ''"
        @update:model-value="() => updateBillingInformation({ addressId: address.id })"
      />
      <Field
        v-model="addressId"
        name="address"
        hidden
        :rules="{ required: true }"
      />
      <ErrorMessage
        name="address"
        class="text-xs text-red-500 md:text-sm"
      />
      <button
        class="flex items-center mb-1 max-w-[12rem] text-xss hover:text-lk-green focus:text-lk-green hover:underline md:text-xs"
        type="button"
        @click="showNewAddressDialog = !showNewAddressDialog"
      >
        <PlusIcon class="w-3 h-3" />
        Agregar una dirección
      </button>
      <lokal-loader-button
        v-if="!billingInformation?.id"
        v-model="createBillingInformationIsLoading"
        action-text="Guardar"
        loading-text="Guardando..."
        action="confirm"
        type="submit"
        class="flex justify-center items-center p-2 max-w-[12rem] text-xs hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
        :class="{ 'bg-lk-green text-white': createBillingInformationIsLoading }"
      />
      <address-new-dialog
        v-model="showNewAddressDialog"
        :addressable-id="maker.id"
        :addressable-type="maker.type"
        @update:model-value="(value: boolean) => showNewAddressDialog = value"
        @address-created="updateModelValueWithAddress"
      />
    </form>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useMutation } from 'vue-query';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import { PlusIcon } from '@heroicons/vue/24/solid';
import { useI18n } from 'vue-i18n';
import billingInformationsApi from 'api/billing-informations';
import useMessageStore from 'stores/message-store';
import useAddressesQuery from './queries/addresses-query';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import autoFormInput from './shared/auto-form-input.vue';
import addressNewDialog from './address-new-dialog.vue';
import lokalLoaderButton from './shared/lokal-loader-button.vue';

const props = defineProps<{
  maker: Maker;
  billingInformation: BillingInformation;
}>();

const messageStore = useMessageStore();
const { t } = useI18n();

const { handleSubmit, errors } = useForm();

const showNewAddressDialog = ref(false);

const computedMakerId = computed(() => props.maker.id);
const { addresses, addressesQuery } = useAddressesQuery(computedMakerId, computed(() => 'Maker'));
const generalLoading = computed(() => addressesQuery.isLoading.value);

const businessRut = ref(props.billingInformation?.businessRut);
const socialReason = ref(props.billingInformation?.socialReason);
const commercialBusiness = ref(props.billingInformation?.commercialBusiness);
const address = ref(props.billingInformation?.address);
const addressId = computed(() => address.value?.id);

async function updateModelValueWithAddress(newAddressId: number) {
  await addressesQuery.refetch.value();
  const foundAddress = addresses.value.find((internalAddress: Address) => internalAddress.id === newAddressId);
  if (foundAddress) address.value = foundAddress;
}

const emit = defineEmits<{(e: 'reload:billingInformation'): void }>();

const updateBillingInformationMutation = useMutation(
  (data: Record<string, string | number>) =>
    billingInformationsApi.update(props.billingInformation.id, data),
  {
    onSuccess: async (_, variables) => {
      let message = 'Datos actualizados correctamente:\n';
      Object.keys(variables).forEach((key) => {
        const keyName = t(`billingInformation.${key}`);
        message += `${keyName}: ${variables[key]}\n`;
      });
      messageStore.showMessage(message, 'success');
      emit('reload:billingInformation');
    },
  },
);

function updateBillingInformation(
  data: {
    socialReason?: string,
    commercialBusiness?: string,
    businessRut?: string,
    addressId?: number,
    bankId?: number,
    account?: string,
    accountType?: string,
  },
) {
  if (props.billingInformation?.id) {
    updateBillingInformationMutation.mutate(data);
  }
}

const createBillinInformationData = computed(() => ({
  socialReason: socialReason.value,
  commercialBusiness: commercialBusiness.value,
  businessRut: businessRut.value,
  addressId: address.value.id,
}));

const createBillingInformationMutation = useMutation(
  () => billingInformationsApi.create(props.maker.id, createBillinInformationData.value),
  {
    onSuccess: async () => {
      messageStore.showMessage('Información de facturación creada correctamente', 'success');
      emit('reload:billingInformation');
    },
  },
);
const createBillingInformationIsLoading = computed(() => createBillingInformationMutation.isLoading.value);

const onSubmit = handleSubmit(() => {
  if (!props.billingInformation?.id) {
    createBillingInformationMutation.mutate();
  }
});
</script>
