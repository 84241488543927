<template>
  <div class="flex flex-col py-5 px-4 w-full h-full bg-gray-50 md:p-10">
    <div class="flex overflow-x-auto gap-6 items-baseline mb-3 md:mb-5">
      <a
        v-for="category in categories"
        :key="`shop-landing-category-${category.id}`"
        :href="`/makers?category_ids=${category.id}`"
        class="flex flex-col shrink-0 justify-center items-center py-2 hover:opacity-50 md:py-5"
      >
        <img
          :src="category.image?.derivatives.jpgSm?.url"
          :alt="category.name"
          class="object-cover shrink-0 w-16 h-16 rounded-full md:w-24 md:h-24"
        >
        <p class="w-16 text-xs text-center break-words md:w-24 md:text-sm">
          {{ category.name }}
        </p>
      </a>
    </div>
    <h2
      v-if="lastMakers && lastMakers.length && lastMakers.length > 0"
      class="text-lg sm:text-xl md:text-2xl"
    >
      Compradas recientemente
    </h2>
    <div class="flex overflow-x-auto gap-3 items-baseline mb-3 md:gap-6 md:mb-5">
      <template v-if="lastMakersIsLoading">
        <div
          v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :key="`shop-landing-last-makers-placeholder-${i}`"
          class="flex flex-col shrink-0 justify-center items-center py-3 hover:opacity-50 md:py-5"
        >
          <div
            class="aspect-[3/2] object-cover shrink-0 w-full h-32 rounded-sm md:h-40"
          >
            <div class="h-full bg-[length:200%_100%] bg-gradient-to-r from-gray-200 via-white to-gray-200 rounded-md animate-shimmer" />
          </div>
        </div>
      </template>
      <template v-else>
        <shop-landing-maker-card
          v-for="maker in lastMakers"
          :key="`shop-landing-last-makers-${maker.id}`"
          :maker="maker"
          :base-key="`shop-landing-last-makers-${maker.id}`"
          :show-badge="true"
          :badge-text="maker.activeDiscounts?.[0]?.name"
        />
      </template>
    </div>
    <h2 class="text-lg sm:text-xl md:text-2xl">
      Tal vez no las conoces
    </h2>
    <div
      class="flex overflow-x-auto gap-3 items-baseline mb-3 md:gap-6 md:mb-5"
    >
      <template v-if="makersNotPurchasedIsLoading">
        <div
          v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :key="`shop-landing-maker-not-purchased-placeholder-${i}`"
          class="flex flex-col shrink-0 justify-center items-center py-3 hover:opacity-50 md:py-5"
        >
          <div
            class="aspect-[3/2] object-cover shrink-0 w-full h-32 rounded-sm md:h-40"
          >
            <div class="h-full bg-[length:200%_100%] bg-gradient-to-r from-gray-200 via-white to-gray-200 rounded-md animate-shimmer" />
          </div>
        </div>
      </template>
      <template v-else>
        <shop-landing-maker-card
          v-for="maker in makersNotPurchased"
          :key="`shop-landing-maker-not-purchased-${maker.id}`"
          :maker="maker"
          :base-key="`shop-landing-maker-not-purchased-${maker.id}`"
        />
      </template>
    </div>
    <h2 class="mb-1.5 text-lg sm:text-xl md:mb-3 md:text-2xl">
      Con despacho gratis a tu dirección
    </h2>
    <div
      class="flex overflow-x-auto gap-3 items-baseline mb-3 md:gap-6 md:mb-5"
    >
      <template v-if="makersNotPurchasedWithFreeShippingIsLoading">
        <div
          v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :key="`shop-landing-maker-not-purchased-with-free-shipping-placeholder-${i}`"
          class="flex flex-col shrink-0 justify-center items-center py-3 hover:opacity-50 md:py-5"
        >
          <div
            class="aspect-[3/2] object-cover shrink-0 w-full h-32 rounded-sm md:h-40"
          >
            <div class="h-full bg-[length:200%_100%] bg-gradient-to-r from-gray-200 via-white to-gray-200 rounded-md animate-shimmer" />
          </div>
        </div>
      </template>
      <template v-else>
        <shop-landing-maker-card
          v-for="maker in makersNotPurchasedWithFreeShipping"
          :key="`shop-landing-maker-${maker.id}`"
          :maker="maker"
          :base-key="`shop-landing-maker-${maker.id}`"
          :show-badge="true"
          badge-text="Despacho gratis"
          badge-kind="discount"
        >
          <template #icon>
            <TruckIcon class="w-4 h-4" />
          </template>
        </shop-landing-maker-card>
      </template>
    </div>
    <h2
      v-if="discountMakers && discountMakers.length && discountMakers.length > 0"
      class="mb-3 text-xl md:text-2xl"
    >
      Marcas con descuentos
    </h2>
    <div
      class="flex overflow-x-auto gap-3 items-baseline mb-3 md:gap-6 md:mb-5"
    >
      <template v-if="discountMakersIsLoading">
        <div
          v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :key="`shop-landing-maker-discount-placeholder-${i}`"
          class="flex flex-col shrink-0 justify-center items-center py-3 hover:opacity-50 md:py-5"
        >
          <div
            class="aspect-[3/2] object-cover shrink-0 w-full h-32 rounded-sm md:h-40"
          >
            <div class="h-full bg-[length:200%_100%] bg-gradient-to-r from-gray-200 via-white to-gray-200 rounded-md animate-shimmer" />
          </div>
        </div>
      </template>
      <template v-else>
        <shop-landing-maker-card
          v-for="maker in discountMakers"
          :key="`shop-landing-maker-discount-${maker.id}`"
          :maker="maker"
          :base-key="`shop-landing-maker-discount-${maker.id}`"
          :show-badge="true"
          :badge-text="maker.activeDiscounts?.[0]?.name"
          badge-kind="discount"
        />
      </template>
    </div>
    <h2
      v-if="newMakers && newMakers.length && newMakers.length > 0"
      class="mb-3 text-xl md:text-2xl"
    >
      Nuevas marcas en Lokal
    </h2>
    <div
      class="flex overflow-x-auto gap-3 items-baseline mb-3 md:gap-6 md:mb-5"
    >
      <template v-if="newMakersIsLoading">
        <div
          v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :key="`shop-landing-maker-discount-placeholder-${i}`"
          class="flex flex-col shrink-0 justify-center items-center py-3 hover:opacity-50 md:py-5"
        >
          <div
            class="aspect-[3/2] object-cover shrink-0 w-full h-24 rounded-sm md:h-40"
          >
            <div class="h-full bg-[length:200%_100%] bg-gradient-to-r from-gray-200 via-white to-gray-200 rounded-md animate-shimmer" />
          </div>
        </div>
      </template>
      <template v-else>
        <shop-landing-maker-card
          v-for="maker in newMakers"
          :key="`shop-landing-maker-new-${maker.id}`"
          :maker="maker"
          :base-key="`shop-landing-maker-new-${maker.id}`"
          :show-badge="true"
          badge-text="Nueva"
          badge-kind="info"
        />
      </template>
    </div>
    <div class="flex justify-center items-center">
      <a
        href="/makers"
        class="p-2 text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:p-3 md:text-base"
      >
        Revisar todas las marcas
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { useQuery } from 'vue-query';
import { TruckIcon } from '@heroicons/vue/24/outline';
import shopsApi from 'api/shops';
import useSessionStore from 'stores/user-store';
import useActiveMakersQuery from './queries/active-makers-query';
import shopLandingMakerCard from './shop-landing-maker-card.vue';

defineProps<{
  categories: Category[];
}>();

const sessionStore = useSessionStore();
const shopId = computed(() => sessionStore.user?.shopId);
const shopEnabled = computed(() => !!shopId.value);
const currentAddress = computed(() => sessionStore.currentAddress);
const lastMakersQuery = useQuery(
  ['last-makers', shopId],
  () => shopsApi.lastMakers(shopId.value),
  {
    enabled: shopEnabled,
  },
);
const lastMakersIsLoading = computed(() => lastMakersQuery.isLoading.value);
const lastMakers = computed(() => lastMakersQuery.data?.value?.data?.makers as Maker[]);

const makersNotPurchasedQuery = useQuery(
  ['makers-not-purchased', shopId],
  () => shopsApi.makersNotPurchased(shopId.value),
  {
    enabled: shopEnabled,
  },
);
const makersNotPurchasedIsLoading = computed(() => makersNotPurchasedQuery.isLoading.value);
const makersNotPurchased = computed(() => makersNotPurchasedQuery.data?.value?.data?.makers as Maker[]);
const makersNotPurchasedWithFreeShippingQueryParams = computed(() => {
  let base = `q[free_shippings_free_shippables_shippable_id_eq]=${currentAddress.value.regionId}`;
  base += '&q[free_shippings_free_shippables_shippable_type_eq]=Region';
  base += '&q[free_shippings_active_eq]=true';

  return base;
});
const makersNotPurchasedWithFreeShippingQueryEnabled = computed(() =>
  !!shopEnabled.value && !!currentAddress.value.regionId);
const makersNotPurchasedWithFreeShippingQuery = useQuery(
  ['makers-not-purchased-with-free-shipping', shopId, makersNotPurchasedWithFreeShippingQueryParams],
  () => shopsApi.makersNotPurchased(shopId.value, makersNotPurchasedWithFreeShippingQueryParams.value),
  {
    enabled: makersNotPurchasedWithFreeShippingQueryEnabled,
  },
);
const makersNotPurchasedWithFreeShippingIsLoading = computed(() =>
  makersNotPurchasedWithFreeShippingQuery.isLoading.value,
);
const makersNotPurchasedWithFreeShipping = computed(() =>
  makersNotPurchasedWithFreeShippingQuery.data?.value?.data?.makers as Maker[],
);

const discountMakersQueryParams = computed(() => {
  const currentDate = new Date();
  let base = `q[products_discounts_start_date_lteq]=${currentDate.toISOString()}`;
  base += `&q[products_discounts_end_date_gteq]=${currentDate.toISOString()}`;

  return base;
});
const currentPage = ref(1);
const discountMakersQueryEnabled = computed(() => true);
const { makers: discountMakers, makersQueryIsLoading: discountMakersIsLoading } = useActiveMakersQuery(
  currentPage, discountMakersQueryParams, discountMakersQueryEnabled,
);

function addNewMakersQueryDate() {
  const daysAgo = 30;
  const currentDate: Date = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setDate(currentDate.getDate() - daysAgo);
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];
  const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0];

  return `q[created_at_gteq]=${formattedOneMonthAgo}&q[created_at_lteq]=${formattedCurrentDate}&`;
}

const newMakersQueryParams = computed(() => addNewMakersQueryDate());
const newMakersQueryEnabled = computed(() => true);
const { makers: newMakers, makersQueryIsLoading: newMakersIsLoading } = useActiveMakersQuery(
  currentPage, newMakersQueryParams, newMakersQueryEnabled,
);
</script>
