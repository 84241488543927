<template>
  <div class="flex flex-col">
    <div
      class="relative p-1.5 rounded-md border border-lk-light-gray focus-within:border-lk-green md:p-2"
    >
      <input
        :value="inputValue"
        :name="name"
        :type="type"
        class="w-full text-xs placeholder:font-light placeholder:text-gray-400 focus:outline-none md:text-sm"
        :class="{ 'mt-2.5 md:mt-3': focused || !!modelValue }"
        :placeholder="focused ? '' : placeholder"
        :rules="rules"
        @focus="focused = true"
        @blur="handleBlur"
        @input="(e) => handleInput((e.target as HTMLInputElement).value)"
      >
      <transition
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform translate-y-2 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
      >
        <p
          v-if="focused || !!inputValue"
          class="absolute top-0.5 left-1.5 text-xss font-light text-gray-400 md:top-1 md:left-2 md:text-xs"
        >
          {{ title || label || name }}
        </p>
      </transition>
    </div>
    <p
      v-if="errorMessage"
      class="text-xs text-red-500 md:text-sm"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useField } from 'vee-validate';
import { rutFormat } from 'rut-helpers';

interface CustomInputProps {
  modelValue: string | number,
  name: string,
  type?: string,
  placeholder?: string,
  label?: string,
  title?: string,
  rules?: Record<string, boolean | string | number | (string | number | undefined)[]>,
}

const props = withDefaults(defineProps<CustomInputProps>(), {
  type: 'text',
  placeholder: '',
  label: '',
  title: undefined,
  rules: () => ({} as Record<string, boolean | string | number | (string | number | undefined)[]>),
});

const computedName = computed(() => props.name);
const { value: inputValue, errorMessage } = useField(computedName, props.rules, {
  initialValue: props.modelValue,
  label: props.label || props.name,
});

const focused = ref(!!props.modelValue);
function handleBlur() {
  focused.value = !!props.modelValue;
}

const emit = defineEmits<{(e: 'update:modelValue', value: string | number): void}>();

function handleInput(value: string | number) {
  if (value === props.modelValue) return;

  if (props.rules?.rut) {
    inputValue.value = rutFormat(`${value}`);
    emit('update:modelValue', rutFormat(`${value}`));
  } else {
    inputValue.value = value;
    emit('update:modelValue', value);
  }
}

onMounted(() => {
  if (props.modelValue && props.rules?.rut) {
    inputValue.value = rutFormat(`${props.modelValue}`);
  }
});
</script>
